<template>

	<div class="row">
		<div class="col-md-3 m-auto">
            <h2 class="text-center mb-30">
                 404 Page Found
               <button type="button" class="btn btn-inverse btn-secondary" v-on:click="goToHome()">Go to Home</button>
        </h2>
            </div>
           
            </div>
</template>

<script>

export default {
    name : 'NotFound',
    data (){
        return{
          

        }
    },
    methods:{
       goToHome(){
           this.$router.push('/homepage');
       }
    }
}
</script>