<template>

   <!-- <div class="mainmenu-area header-sticky display-none">
      <div class="container-fluid">
         <div class="menu-wrapper">
            <div class="main-menu">
               <div class="row">
               <div class="col-md-3 d-inline">
                For News Letter <input type="" class="form-control" name="url" value="index.html">   
                 <button class="btn btn-secondary">Submit</button>   
               </div>
               </div> 
            </div>
         </div>
      </div>
   </div> -->

   <div class="footer-top pt--20  rv-cls-2">
         <section class="newsletter pt-5" style="background-color:#fff">
        <div class="container">
            <div class="row">
                <div class="col-md-6" >
                   <div class="row">
                      <div class="col-lg-5 col-12" >
                         
                               <h3 class="my-auto mr-2 font-weight-bold">GET OFFERS</h3>
                      </div>
                      <div class="col-lg-4 col-9">
                         
                              
                                <input type="email" class="form-control mt-2 mt-md-0" placeholder="Enter your email">
                      </div>
                      <div class="col-lg-3 col-3">
                         
                           
                                    <button class="btn btn-sm mt-2 mt-md-0 pl-0 pl-md-1" type="submit">Sign Up</button>
                      </div>
                   </div> 
                </div>
                <div class="col-md-6">
                    <div class="content mt-2 mt-md-0">
                        <form> 
                           <h2>
                               <ul class="social-network social-circle" style="
  border: 2px solid #000;
  border-radius: 10px;
  padding: 10px;"> 
                        <li><a :href="domainProfile?.configurationData?.social?.facebook" target="_blank"><i class="fa fa-facebook"></i></a></li>
                        <li><a :href="domainProfile?.configurationData?.social?.twitter"  target="_blank"><i class="fa fa-twitter"></i></a></li>
                        <li><a :href="domainProfile?.configurationData?.social?.instagram"  target="_blank"><i class="fa fa-instagram"></i></a></li>
                        <li><a :href="domainProfile?.configurationData?.social?.youtube"  target="_blank"><i class="fa fa-youtube"></i></a></li>
                    </ul>	

                           </h2>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
      <div class="container pt-4">
         <div class="row">
             <div class="col-lg-3 col-md-12">
      <div class="footer-info">
         <div class="footer-title mb-2 mb-md-3 mt-2 mt-md-1">
            <h3>Contact Info</h3>
         </div>
         <ul class="footer-info-list">
            <li> <i class="ion-ios-location-outline"></i> 
            {{ domainProfile?.configurationData?.footer?.contact?.address  }} 
            </li>
            <li> <i class="ion-ios-email-outline"></i> Mail Us : <a :href="email"> {{ domainProfile?.configurationData?.footer?.contact?.email }}</a></li> 
            <li> <i class="ion-ios-telephone-outline"></i> Phone : <a target="_blank"  :href="whatsAppWithCode">{{ domainProfile?.configurationData?.footer?.contact?.phone }}</a></li>
            <li> <i class="ion-ios-email-outline"></i> Mail Us : <a :href="email"> {{ domainProfile?.configurationData?.footer?.contact?.email }}</a></li>
            <!-- <li> <i class="ion-ios-telephone-outline"></i> Phone : <a :href="phoneWithCode">+{{ domainProfile.phone_country_code?.phonecode }}-
               {{ domainProfile.phone }}</a></li> -->
         </ul>
         <div class="footer-social font20"> 
         <iframe src="https://www.google.com/maps?q=Venus+Enterprises+-+9Gem.com,+Krishna+Square+II,+Krishna+Square,+Amritsar,+Punjab+143001/@31.6247761,74.8947343,14z/data=!4m6!3m5!1s0x3919634ed7e434fd:0xef51b760a16f081a!8m2!3d31.6385313!4d74.8909807!16s%2Fg%2F11b6f5034f&output=embed"
 frameborder="0" style="width : 100%" class="pt-5" allowfullscreen></iframe> 
 
         </div>
      </div>
   </div>
            <div class="col-lg-2 col-md-6 hidemd">
               <div class="footer-info">
                  <div class="footer-title mb-2 mb-md-3 mt-2 mt-md-1">
                     <h3>Products</h3>
                  </div> 

                  <ul class="footer-list">
                     <li class="submenu" v-for="product in products" :key="product.id">
                     <router-link :to="{ name:'Product', params: { productCategoryId : product?.product_category?.url?.content ?? product?.product_category?.id, productId : product?.url?.content ?? product?.id }}">{{ product.name }}</router-link>  
                     </li> 
                  </ul>
               </div>
            </div>
            <div class="col-lg-2 col-md-6">
               <div class="footer-info">
                  <div class="footer-title mb-2 mb-md-3 mt-2 mt-md-1">
                     <h3>Policies</h3>
                  </div>
                  <ul class="footer-list">
                     
                        <li  v-for="page in policyPages" :key="page.id">
                                 <router-link :to="{ name: 'pageView', params: { id: page.url ?? page.id }}">{{page.title}}</router-link>
                        </li> 
                  </ul>
               </div>
            </div>
            <div class="col-lg-2 col-md-6">
               <div class="footer-info">
                  <div class="footer-title mb-2 mb-md-3 mt-2 mt-md-1">
                     <h3>Know Us</h3>
                  </div>
                  <ul class="footer-list">
                        <li  v-for="page in knowUsPages" :key="page.id">
                                 <router-link :to="{ name: 'pageView', params: { id: page.url ?? page.id }}">{{page.title}}</router-link>
                        </li> 
                  </ul>
               </div>
            </div>
            <div class="col-lg-3 col-md-12">
               <div class="footer-info"> 
                  <div class="footer-title mb-2 mb-md-3 mt-2 mt-md-1">
                     <h3>Have Any Query?</h3>
                  </div>
                  <form action="https://9gem.com/mail.php" method="post" class="footerform" id="footerform">
                     <input type="hidden" class="form-control" name="url" value="index.html">    
                     <input type="text" class="form-control" name="name" placeholder="Enter Your Name" required>
                     <input type="email" class="form-control" name="email" placeholder="Enter Your Email" required>
                     <input type="text" class="form-control" name="phone" placeholder="Enter Your Contact No." required>
                     <textarea class="form-control" name="message" rows="3" placeholder="Message" required></textarea>
                     <!-- <div class="g-recaptcha" style="display:none;" data-sitekey="6LfDRFMaAAAAALzhIxdKBMZFSMQVl0Vf1LYHyKQl" style="transform:scale(0.70);transform-origin:0 0;-webkit-transform-origin:0 0;"></div> -->
                     <button class="btn2 btn-secondary sending py-2" style="background-color:lightgray;text-color:white;" name="submit" type="submit"> Submit  </button>
                  </form>
               </div>
            </div>
         </div>
           <div class="row">
              
        <div class="col text-center"> 
         <p class="text-center mt-4 pb-2">©{{ currentYear }}{{ domainProfile?.configurationData?.footer?.copyright  }}</p>  
    </div>
     </div>
      </div>
   
   </div>
   
    
  <div class="whatsapp-chat-icon" @click="openWhatsApp">
    <img src="/whats_app_icon.png" alt="WhatsApp" />
  </div>
</template>
<script>  
   import {mapGetters } from 'vuex'
   export default { 
       name:'Footer', 
       computed :{
            ...mapGetters({  
               domainProfile : 'domain/profile',
               products : 'frontend/getProducts',
               policyPages : 'frontend/getPolicyPages',
               knowUsPages : 'frontend/getKnowUsPages',
            }), 
            email(){
               return `mailto:${this.domainProfile?.configurationData?.footer?.contact?.email}`;
            },
            phoneWithCode(){
               return `tel:${this.domainProfile?.configurationData?.footer?.contact?.phone}`;
            },
            whatsAppWithCode(){
               return `https://wa.me/${this.domainProfile?.configurationData?.footer?.contact?.phone}`;
            },
            currentYear() {
               return new Date().getFullYear();
            },
       },
       methods: {
          
         openWhatsApp() {
            const phoneNumber = this.domainProfile?.configurationData?.footer?.contact?.phone;
            const whatsappURL = `https://wa.me/${phoneNumber}`;
            window.open(whatsappURL, '_blank');
         }, 
       }
   }
</script>

<style scoped>
   .newsletter {
padding: 20px 0;
/* background: #f2f2f2; */
}

.newsletter .content {
/* max-width: 650px; */
margin: 0 auto;
text-align: center;
position: relative;
z-index: 2; }
.newsletter .content h2 {
color: #243c4f;
/* margin-bottom: 40px; */
 }
.newsletter .content .form-control {
height: 50px;
border-color: #ffffff;
border-radius:0;
}
.newsletter .content.form-control:focus {
box-shadow: none;
border: 2px solid #243c4f;
}
.newsletter .content .btn {
min-height: 50px; 
border-radius:0;
background: #243c4f;
color: #fff;
font-weight:600;
}
ul.social-network {
	list-style: none;
	display: inline;
	margin-left:0 !important;
	padding: 0;
}
ul.social-network li {
	display: inline;
	margin: 0 5px;
}


/* footer social icons */
.social-network a.icoRss:hover {
	background-color: #F56505;
}
.social-network a.icoFacebook:hover {
	background-color:#3B5998;
}
.social-network a.icoTwitter:hover {
	background-color:#33ccff;
}
.social-network a.icoGoogle:hover {
	background-color:#BD3518;
}
.social-network a.icoVimeo:hover {
	background-color:#0590B8;
}
.social-network a.icoLinkedin:hover {
	background-color:#007bb7;
}
.social-network a.icoRss:hover i, .social-network a.icoFacebook:hover i, .social-network a.icoTwitter:hover i,
.social-network a.icoGoogle:hover i, .social-network a.icoVimeo:hover i, .social-network a.icoLinkedin:hover i {
	color:#fff;
}
a.socialIcon:hover, .socialHoverClass {
	color:#44BCDD;
}

.social-circle li a {
	display:inline-block;
	position:relative;
	margin:0 auto 0 auto;
	-moz-border-radius:50%;
	-webkit-border-radius:50%;
	border-radius:50%;
	text-align:center;
	width: 50px;
	height: 50px;
	font-size:20px;
}
.social-circle li i {
	margin:0;
	line-height:50px;
	text-align: center;
}

.social-circle li a:hover i, .triggeredHover {
	-moz-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
	-ms--transform: rotate(360deg);
	transform: rotate(360deg);
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	-ms-transition: all 0.2s;
	transition: all 0.2s;
}
.social-circle i {
	color: black;
	-webkit-transition: all 0.8s;
	-moz-transition: all 0.8s;
	-o-transition: all 0.8s;
	-ms-transition: all 0.8s;
	transition: all 0.8s;
}

input:not([type="hidden"]) + input  { 
    margin-left: 0rem;
} 
/* .footer-info-list li{
   line-height : 20px;
}.footer-info ul.footer-list li{
   margin-bottom-
} */
</style>