<template>
    <div class="a-section a-spacing-base">
      <div class="a-box">
        <div class="a-box-inner a-padding-extra-large">
          <h1>
            Password assistance
          </h1>
          <div class="a-row a-spacing-none">
            <p>
              
<template v-if="domainProfile?.isFromIndia">
    
              Enter the email address or mobile phone number associated with your Gemlab account.
</template>
<template v-else>
              Enter the email address associated with your Gemlab account.
</template>
              </p>
          </div>
          <!-- <form  @submit="event.preventDefault()"  method="post" novalidate="" class="auth-validate-form auth-real-time-validation a-spacing-none" data-fwcim-id="h6qrgJYL"> -->
          <div class="a-section">
            <div class="a-section a-spacing-large">
              <div class="a-row a-spacing-base" data-v-776b4554="">
                <label for="ap_email" class="a-form-label" data-v-776b4554=""> 
                
            <template v-if="domainProfile?.isFromIndia"> 
            Email or mobile phone number 
            </template>
            <template v-else> 
            Enter email address
            </template>
                 </label>
                <input autocomplete="off" ref="focus" type="email" v-model="phone" maxlength="128" id="ap_email"  v-on:keyup.enter="forgotPassword()" class="a-input-text a-span12 auth-autofocus auth-required-field" data-v-776b4554="">
                <span class="text-danger">{{errors.phone ?? ""}}</span>
              </div>
            </div>
            <div class="a-section" data-v-776b4554="">
              <span id="continue" class="a-button a-button-span12 a-button-primary" data-v-776b4554=""><span class="a-button-inner" data-v-776b4554=""><input autocomplete="off" id="continue" @click="forgotPassword()" class="a-button-input" type="submit" aria-labelledby="continue-announce" data-v-776b4554=""><span id="continue-announce" class="a-button-text" aria-hidden="true" data-v-776b4554=""> Continue </span></span></span> 
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div> 
</template>
<script>
import {HTTP} from '../../../http-common'
import {mapGetters} from 'vuex'
export default {
    name:'ForgotPassword',
      data (){
      return {
         phone : '',
         errors: {
            phone:'',
         },
      }
    },
    mounted(){
      this.$refs.focus.focus();
    },
    computed :{
          ...mapGetters({ 
              domainProfile : 'domain/profile', 
          }),  
    },
    methods:{ 
      forgotPassword(){
         this.errors = {},
         HTTP.post(`forgot-password-send-otp`,{
            phone : this.phone
         }).then(({data}) => {
            if(!data.errors){
               this.$router.push({
                 name: 'ForgotPasswordOtp',
                 query: {
                   userId : data.userId, 
                   isPhone : data.is_phone, 
                   phone : data.phone,
                   regId : data.regId,
               }
            });
            }else{
               this.errors = data.errors;
            }
         })
      }, 
    }, 
}
</script>
  