<template>
    <div> 
      <Header></Header>
      <router-view :key="$route.fullPath" />

      <MainFooter></MainFooter>
   </div>
</template>
<script>  
import Header from './Header.vue'
import MainFooter from './Footer.vue'  
import {mapActions} from 'vuex' 

export default({
  name: 'DefaultLayout',
  components: {
          Header,
          MainFooter
  }, 
  data(){
    return {
      refresh: false,
      isLoading: false,
      axiosInterceptor: null,
    }
  },
  mounted() {
    if(localStorage.getItem('oneTimeRefresh')){
      localStorage.removeItem('oneTimeRefresh')
      location.reload();
    }
  },
  created(){
    if(localStorage.getItem('authToken') ?? false){
      if(!this.$root.refresh){ 
        this.authUser().then(()=>{
          
            if(localStorage.getItem('authToken') && localStorage.getItem('itemForCart')){
              
            this.$store.dispatch('cart/save',localStorage.getItem('itemForCart'))
                  .then((data) => { 
                        if(data.errors){
                            console.log(data.errors);
                        }else{  
                            this.$router.push({name : 'Cart'});
                        }
                  });
                  
              localStorage.removeItem('itemForCart')
            }
        });
      }
    }
    this.fetchProfile(); 
  },
  methods:{
       ...mapActions({
         'authUser' : 'auth/authUser', 
         'fetchProfile' : 'domain/fetchProfile'
       }), 
  }
});

</script>