import {createRouter,createWebHistory} from 'vue-router'
import store from '../store/main' 

//Frontend
import DefaultLayout from '../components/layouts/DefaultLayout.vue'
import Home from '../views/frontend/home/Home.vue'

//Auth
import Auth from '../views/auth/DefaultLayout.vue'
import Login from '../views/auth/login/Login.vue'
import LoginPassword from '../views/auth/login/LoginPassword.vue'
import LoginOtp from '../views/auth/login/Otp.vue'
import Register from '../views/auth/register/Register.vue'
import RegisterOtp from '../views/auth/register/Otp.vue'
import ForgotPassword from '../views/auth/forgotPassword/ForgotPassword.vue' 
import ForgotPasswordOtp from '../views/auth/forgotPassword/Otp.vue' 
import ForgotPasswordCreate from '../views/auth/forgotPassword/PasswordCreate.vue' 

import Product from '../views/frontend/product/View.vue'
import ItemView from '../views/frontend/item/FullView.vue'

//Cms
import BlogIndex from '../views/cms/blog/index.vue'
import BlogCategory from '../views/cms/blog/category.vue'
import BlogView from '../views/cms/blog/view.vue'
import PageView from '../views/cms/page/View.vue'

//Cart
import Cart from '../views/frontend/cart/Cart.vue'

//Checkout
import Checkout from '../views/frontend/checkout/Checkout.vue'
import Pay from '../views/frontend/checkout/Payment.vue'

//Dashboard
import Dashboard from '../views/frontend/dashboard/Index.vue'

//Orders
import OrderIndex from '../views/frontend/dashboard/order/Index.vue'
import OrderAll from '../views/frontend/dashboard/order/All.vue' 
import OrderView from '../views/frontend/dashboard/order/View.vue' 

//Returns 
import CreateReturn from '../views/frontend/dashboard/return/CreateReturn.vue' 

//Profile
import EditProfile from '../views/frontend/dashboard/profile/Edit.vue' 

//Address
import AddressIndex from '../views/frontend/dashboard/address/Index.vue'
import AddressAll from '../views/frontend/dashboard/address/All.vue'
import AddressCreate from '../views/frontend/dashboard/address/Create.vue'
import AddressEdit from '../views/frontend/dashboard/address/Edit.vue'

//Layouts
import NotFound from '../components/layouts/NotFound.vue'

//Check 
// import Payment1 from '../components/checkout/Payment.vue'
// import Account from '../components/customer/Account.vue'
// import CustomerProfile from '../components/customer/CustomerProfile.vue'
// import ChangePassword from '../components/customer/EditPassword.vue'
// import EditName from '../components/customer/EditName.vue'
// import EditContact from '../components/customer/EditContact.vue'
// import EditEmail from '../components/customer/EditEmail.vue'
// import EditGender from '../components/customer/EditGender.vue'
// import Address from '../components/customer/Address.vue'
// import CreateAddress from '../components/customer/CreateAddress.vue'
// import EditAddress from '../components/customer/EditAddress.vue'

// import OrderHistory from '../components/customer/OrderHistory.vue'
// import OrderDetails from '../components/customer/order/OrderDetails.vue'

// import Wishlist from '../views/pages/wishlist/Wishlist.vue'


// import CheckoutAddress from '../views/pages/checkout/address.vue'
// import orderAddressReview from '../views/pages/orderAddressReview/index.vue'
// import OrdersAll from '../views/dashboard/orders/all.vue'
// import OrderView from '../views/dashboard/orders/view.vue'
// import Payment from '../views/dashboard/payment/index.vue'
// import Profile from '../views/dashboard/profile/Index.vue'
// import EditProfile from '../views/dashboard/profile/Edit.vue'
// import AddressMain from '../views/dashboard/address/Main.vue'
// import AddressList from '../views/dashboard/address/AddressList.vue'
// import AddressCreate from '../views/dashboard/address/Create.vue'
// import AddressEdit from '../views/dashboard/address/Edit.vue'
// import DeliveryAddress from '../views/pages/delivery_address/DeliveryAddress.vue'
// import PaymentMethod from '../views/pages/payment/PaymentMethod.vue'
// import ReviewYourOrder from '../views/pages/review_order/ReviewOrder.vue'
// import Success from '../views/dashboard/orders/Success.vue'
// import Failure from '../views/dashboard/orders/Failure.vue'

function isGuest(to, from, next) {
   if (!localStorage.getItem('authToken')){
      next();
   }else{
      next({name : 'Dashboard'});
   } 
 }

function isAuth(to, from, next) {
   if (localStorage.getItem('authToken')){
      next();
   }else{
      console.log(to);
      localStorage.setItem('redirect',to.fullPath)
      next({name : 'Login'});
   } 
 }
 

const routes = [
   {
     path: '/',
     redirect: '/home',
     name: 'Root',
     component: DefaultLayout,
     children: [{
           path: '/',
           name: 'Homepage',
           component: Home,
        }, 
        {
           path: 'product/:productCategoryId/:productId',
           name: 'Product',
           component: Product
        }, 
        {
           path: '/item/:itemId',
           name: 'itemView',
           component: ItemView
        },
        {
           path: 'blog/:page?',
           name: 'blogIndex',
           component: BlogIndex, 
        },
        {
           path: '/blog/:categoryUrl/:url',
           name: 'blogView',
           component: BlogView
        },
        {
           path: '/:id',
           name: 'pageView',
           component: PageView
        },
        {
           path: '/blog/:categoryId/:page?',
           name: 'blogCategoryIndex',
           component: BlogCategory
        },
         {
           path : '/checkout',
           name : 'Checkout',
           beforeEnter: [isAuth],
           component : Checkout,
           meta : {requiresAuth : true}
         },
         {
           path: '/cart',
           name: 'Cart',
           component: Cart,
           beforeEnter: [isAuth],
           meta : {requiresAuth : true}
         }, 
         {
            path : '/dashboard',
            name : 'Dashboard',
            component : Dashboard,
            beforeEnter: [isAuth],
            props: true,
            meta : { requiredAuth : true }, 
            children : [
            ]
         },
         {
          path : '/address',
          redirect: {name : 'AddressAll'},
          name : 'Address',
          component : AddressIndex,
          beforeEnter: [isAuth],
          meta : { requiredAuth : true },
          children : [
             {
                path : 'create',
                name : 'AddressCreate',
                component : AddressCreate
             },
             {
                path : 'all',
                name : 'AddressAll',
                component : AddressAll
             },
             {
                path : 'edit/:id?',
                name : 'AddressEdit',
                component : AddressEdit
             },
          ]
       },
       {
         path : '/order',
         redirect: {name : 'OrderAll'},
         name : 'Order',
         component : OrderIndex,
         beforeEnter: [isAuth],
         meta : { requiredAuth : true },
         children : [ 
            {
               path : 'all',
               name : 'OrderAll',
               component : OrderAll
            }, 
            {
               path : 'view/:id',
               name : 'OrderView',
               component : OrderView
            }, 
         ]
       },
       {
         path : '/create-return/:orderId', 
         name : 'CreateReturn',
         component : CreateReturn,
         beforeEnter: [isAuth],
         meta : { requiredAuth : true },
         // children : [ 
         //    {
         //       path : 'all',
         //       name : 'OrderAll',
         //       component : OrderAll
         //    }, 
         //    {
         //       path : 'view/:id',
         //       name : 'OrderView',
         //       component : OrderView
         //    }, 
         // ]
       },
       {
         path : '/edit-profile', 
         name : 'EditProfile',
         component : EditProfile,
         beforeEnter: [isAuth],  
       },
     ]
   },
   {
     path: '/auth', 
     name: 'Auth',
     component: Auth,
     children: [{
           path: '/login',
           name: 'Login',
           component: Login, 
           beforeEnter: [isGuest],
           meta : {isGuest : true}
        },
        {
         path: '/login-password',
         name: 'LoginPassword',
         component: LoginPassword, 
         beforeEnter: [isGuest],
         meta : {isGuest : true}
      },
      {
         path: '/login-otp',
         name: 'LoginOtp',
         component: LoginOtp, 
         beforeEnter: [isGuest],
         props: true
      }, 
      {
         path: '/register',
         name: 'Register',
         component: Register, 
         beforeEnter: [isGuest],
         meta : {isGuest : true}
      },
      {
         path: '/register-otp',
         name: 'RegisterOtp',
         beforeEnter: [isGuest],
         component: RegisterOtp, 
         props: true
      },
      {
         path: '/forgot-password',
         name: 'ForgotPassword',
         component: ForgotPassword, 
         beforeEnter: [isGuest],
         meta : {isGuest : true}
      },
      {
         path: '/forgot-password-otp',
         name: 'ForgotPasswordOtp',
         component: ForgotPasswordOtp, 
         beforeEnter: [isGuest],
         meta : {isGuest : true}
      },
      {
         path: '/forgot-password-create',
         name: 'ForgotPasswordCreate',
         component: ForgotPasswordCreate, 
         beforeEnter: [isGuest],
         meta : {isGuest : true}
      },
     ]
  }, 
  {
   path : '/pay',
   name : 'Pay',
   component : Pay,
   beforeEnter: [isAuth],
   meta : {requiresAuth : true}
  },
//   {
//      path: '/forgot-password',
//      name: 'forgot',
//      component: ForgotPassword,
//      beforeEnter: (to, from, next) => {
//         if (localStorage.getItem('authToken')) {
//            next('Checkout');
//         } else {
//            next();
//         }
//      }
//   }, 
  {
     path: '/:pathMatch(.*)*',
     name: 'NotFound',
     component: NotFound
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  scrollBehavior() {
   window.scrollTo(0,0);
 },
  base: 'process.env.BASE_URL',
})

// router.beforeEach((to,from,next) =>{
//    if(to.meta.requiresAuth && !store.state.auth.token){
//        next({name : 'Login'})
//    }else if (to.name === 'Login' || to.name === 'Register' && store.state.auth.token) {
//       next({name : 'Dashboard'})
//    }else{
//       next();
//    }
// });

// function logout() {
//   if (localStorage.getItem('authToken')) {
//      store.dispatch('auth/logout').then(res => {
//         if (res) {
//            alert(res);
//         }
//      });
//   }
// } 

export default router