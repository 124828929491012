<template>
   <div>
      <div class="blog-main-wrapper section-padding">
         <div class="container">
            <div class="row">
               <div class="col-lg-3 order-2 order-lg-1">
                  <aside class="blog-sidebar-wrapper">
                     <div class="blog-sidebar">
                        <h5 class="title">Categories</h5>
                        <ul class="blog-archive blog-category">
                           <li v-for="(category) in postCategories" :key="category.id">
                              <router-link :to="{ name: 'blogCategoryIndex', params: { categoryId: category.slug }}">{{ category.name }}</router-link>
                           </li>
                        </ul>
                     </div>
                  </aside>
               </div>
               <div class="col-lg-9 order-1 order-lg-2">
                  <div class="blog-item-wrapper blog-list-inner">
                     <div class="row mbn-30">
                        <div v-for="post in posts" :key="post.id" class="col-12"> 
                           <div class="blog-post-item mb-30">
                              <figure class="blog-thumb">
                                 <a href="blog-details.html">
                                 <img :src="cover(post)" alt="blog image">
                                 </a>
                              </figure>
                              <div class="blog-content mb-5">
                                 <h4 class="blog-title">
                                 <router-link :to="{ name: 'blogView', params: { categoryUrl : post.categories[0].slug , url: post.url ?? post.id }}">{{ post.title }}</router-link>  
                                 </h4>
                                 <div class="blog-meta">
                                 </div>
                                 <p>{{ post.description }}</p>
                                 <router-link :to="{ name: 'blogView', params: { categoryUrl : post.categories[0].slug , url: post.url ?? post.id }}">Read More...</router-link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
                  <InfiniteLoading @infinite="getPosts" />
   </div>
</template>
<script>  
import {
   HTTP
} from '../../../http-common' 
  import InfiniteLoading from "v3-infinite-loading";
export default {
   name: 'Blog',
   data() {
      return {
         posts: { 
         },
         postCategories: {},
         page : 0, 
      }
   }, 
   components :{
      InfiniteLoading
   },
   mounted() {
      // this.getPosts();
      this.getPostCategories();
   }, 
   methods: {
       getPosts() {
          let loader = this.$loading.show({ 
                    canCancel: false, 
            });  
            this.page++;
             HTTP.get(`posts?page=${this.page}`).then(({ data }) => { 
               console.log(data);
               if(this.page == 1){
                  this.posts = data.data;
               }else{
                  this.posts = [...this.posts, ...data.data];
               } 
            }).finally(() => {
               loader.hide() ;
            }); 
      },
      getPostCategories: function () {
         HTTP.get(`post-categories`).then(res => {
            this.postCategories = res.data.categories
         });
      },
      cover(post) {
         if (post.cover_photo_url) {
            return this.postCoverUrl(post.cover_photo_url);
         } else {
            return this.postCoverUrl('images/post-cover-demo.jpg');
         }
      },
   }
}
</script>

<style scoped>
.section-padding {
    padding-top: 0px;
    padding-bottom: 80px;
}
</style>