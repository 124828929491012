<template> 
  <div class="a-box a-spacing-extra-large">
    <div class="a-box-inner">
      <h1 class="a-spacing-small moa_desktop_signup">
        Create Account
      </h1>
      <!-- optional subheading element -->
      <div class="a-row a-spacing-base">
        <label for="ap_customer_name" class="a-form-label">
        Your name
        </label>
        <input autocomplete="off" ref="focus"  type="text" maxlength="50" id="ap_customer_name" placeholder="First and last name" v-model="user.name" class="a-input-text a-span12 auth-autofocus auth-required-field">
        <div id="auth-customerName-missing-alert" v-if="errors.name" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
          <div class="a-box-inner a-alert-container">
            <i class="a-icon a-icon-alert"></i>
            <div class="a-alert-content">
              {{errors.name}}
            </div>
          </div>
        </div>
      </div>
      
      <template v-if="domainProfile?.isFromIndia"> 
      <div class="a-section a-spacing-base ap_mobile_number_fields">
        <div class="a-row">
          <label for="ap_phone_number" class="a-form-label auth-mobile-label">
          Mobile number
          </label>
          <div class="a-fixed-left-grid a-spacing-top-mini">
            <div class="a-fixed-left-grid-inner" style="padding-left:85px">
              <div class="a-fixed-left-grid-col a-col-left" style="width:85px;margin-left:-85px;float:left;">
                <span class="a-dropdown-container"> 
                  <select class="custom-select form-control py-0" style="height:30px;line-height:28px;font-size:13px !important;" v-model="user.country_code">
                    <option value="0">Country Code</option>
                    <option v-for="code in countryCodes" :key="code.id" :value="code.id">+{{ code.phonecode }}</option>
                  </select> 
                </span>
              </div>
              <div class="a-fixed-left-grid-col a-col-right" style="padding-left:2%;float:left;">
                <input autocomplete="off" type="tel" maxlength="50" id="ap_phone_number" placeholder="Mobile number" v-model="user.phone" class="a-input-text a-span12 a-spacing-micro auth-required-field auth-require-phone-validation" data-validation-id="phoneNumber">
                <div id="auth-customerName-missing-alert" v-if="errors.phone" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
                  <div class="a-box-inner a-alert-container">
                    <i class="a-icon a-icon-alert"></i>
                    <div class="a-alert-content">
                      {{errors.phone}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="a-section a-spacing-base ap_email_fields">
        <div class="a-row a-spacing-micro">
          <label for="ap_email" class="a-form-label">
          Email (Optional)
          </label>
          <input autocomplete="off" type="email" maxlength="64" id="ap_email" v-model="user.email" class="a-input-text a-span12 auth-require-email-validaton">
          <div id="auth-customerName-missing-alert" v-if="errors.email" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
            <div class="a-box-inner a-alert-container">
              <i class="a-icon a-icon-alert"></i>
              <div class="a-alert-content">
                {{errors.email}}
              </div>
            </div>
          </div>
        </div>
      </div>
      </template>
      <template v-else> 
      
      <div class="a-section a-spacing-base ap_email_fields">
        <div class="a-row a-spacing-micro">
          <label for="ap_email" class="a-form-label">
          Email
          </label>
          <input autocomplete="off" type="email" maxlength="64" id="ap_email" v-model="user.email" class="a-input-text a-span12 auth-require-email-validaton">
          <div id="auth-customerName-missing-alert" v-if="errors.email" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
            <div class="a-box-inner a-alert-container">
              <i class="a-icon a-icon-alert"></i>
              <div class="a-alert-content">
                {{errors.email}}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="a-section a-spacing-base ap_mobile_number_fields">
        <div class="a-row">
          <label for="ap_phone_number" class="a-form-label auth-mobile-label">
          Mobile number (Optional)
          </label>
          <div class="a-fixed-left-grid a-spacing-top-mini">
            <div class="a-fixed-left-grid-inner" style="padding-left:85px">
              <div class="a-fixed-left-grid-col a-col-left" style="width:85px;margin-left:-85px;float:left;">
                <span class="a-dropdown-container"> 
                  <select class="custom-select form-control py-0" style="height:30px;line-height:28px;font-size:13px !important;" v-model="user.country_code">
                    <option value="0">Country Code</option>
                    <option v-for="code in countryCodes" :key="code.id" :value="code.id">+{{ code.phonecode }}</option>
                  </select> 
                </span>
              </div>
              <div class="a-fixed-left-grid-col a-col-right" style="padding-left:2%;float:left;">
                <input autocomplete="off" type="tel" maxlength="50" id="ap_phone_number" placeholder="Mobile number" v-model="user.phone" class="a-input-text a-span12 a-spacing-micro auth-required-field auth-require-phone-validation" data-validation-id="phoneNumber">
                <div id="auth-customerName-missing-alert" v-if="errors.phone" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
                  <div class="a-box-inner a-alert-container">
                    <i class="a-icon a-icon-alert"></i>
                    <div class="a-alert-content">
                      {{errors.phone}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </template>
      <div class="auth-require-fields-match-group">
        <div class="a-row a-spacing-base">
          <label for="ap_password" class="a-form-label">
          Password
          </label>
          <input autocomplete="off" type="password" maxlength="1024"  v-on:keyup.enter="register()" id="ap_password" placeholder="At least 6 characters" v-model="user.password" class="a-input-text a-span12 auth-required-field auth-require-fields-match auth-require-password-validation">
          <div class="a-box a-alert-inline a-alert-inline-info auth-inlined-information-message a-spacing-top-mini" aria-live="polite" aria-atomic="true">
            <div class="a-box-inner a-alert-container">
              <i class="a-icon a-icon-alert"></i>
              <div class="a-alert-content">
                Passwords must be at least 6 characters.
              </div>
            </div>
          </div>
          <div id="auth-customerName-missing-alert" v-if="errors.password" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
            <div class="a-box-inner a-alert-container">
              <i class="a-icon a-icon-alert"></i>
              <div class="a-alert-content">
                {{errors.password}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="a-section a-spacing-extra-large ap_mobile_number_fields">
        <span id="auth-continue" class="a-button a-button-span12 a-button-primary auth-requires-verify-modal"><span class="a-button-inner"><input autocomplete="off"  @click.prevent="register()" id="continue" class="a-button-input" type="submit" aria-labelledby="auth-continue-announce"><span id="auth-continue-announce" class="a-button-text" aria-hidden="true">
        Continue
        </span></span></span>
      </div>
      <div class="a-divider a-divider-break" data-v-776b4554="">
        <h5 data-v-776b4554=""> Already have an account? </h5>
      </div>
      <span id="auth-create-account-link" class="a-button a-button-span12 a-button-base" data-v-776b4554="">
        <span class="a-button-inner" data-v-776b4554="">
          <router-link to="login"  data-toggle="modal" data-target="#registerform" class="a-button-text" data-v-776b4554="">Click to login</router-link>
        </span>
      </span>
    </div>
  </div> 
</template>
<script> 
import {mapGetters,mapActions} from 'vuex'
import {HTTP} from '../../../http-common'
export default {
   name:'register',
   data(){
     return{
            user:{
                 name: '',
                 country_code : 99,
                 phone: '',
                 email: '',
                 password: '',
                 password_confirmation :'',
            }, 
            errors:''
      }
   },
    mounted(){
      this.$refs.focus.focus();
    },
   computed:{
          ...mapGetters({ 
            countryCodes:['address/getCountryCodes'],
            domainProfile : 'domain/profile', 
    }), },
   methods : {
      ...mapActions({
         fetchCountryCodes :'address/fetchCountryCodes', 
         login :'auth/login', 
      }),
      register(){
         this.errors = {},
         HTTP.post('register',this.user)
         .then(({data}) => { 
               if(!data.errors){ 
                  this.$router.push({
                     name: 'RegisterOtp',
                     query: {
                        userId : data.userId, 
                        regIdPhone : data.regIdPhone ?? false, 
                        regIdEmail : data.regIdEmail ?? false, 
                        isPhone : data.isPhone, 
                        phone : data.phone, 
                        email : data.email, 
                     }
                  });
               }else{
                  this.errors = data.errors;
               }
         })  
      }, 
    },
  created(){
        this.fetchCountryCodes();
      },
}
</script>
<style scoped>
   
</style>