<template>
  <div class="a-section">
    <div class="a-box" data-v-776b4554="">
      <div class="a-box-inner a-padding-extra-large" data-v-776b4554="">
        <h1 class="a-spacing-small" data-v-776b4554=""> Sign in </h1>
        <div class="a-row a-spacing-base" data-v-776b4554="">
          <label for="ap_email" class="a-form-label" data-v-776b4554="">
            <template v-if="domainProfile?.isFromIndia"> 
            Email or mobile phone number 
            </template>
            <template v-else> 
            Enter email address
            </template>
            </label>
          <input autocomplete="off" ref="focus" type="email" v-model="phone" maxlength="128" id="ap_email"  v-on:keyup.enter="login()" class="a-input-text a-span12 auth-autofocus auth-required-field" data-v-776b4554="">
          <span class="text-danger">{{errors.phone ?? ""}}</span>
        </div>
        <div class="a-section" data-v-776b4554="">
          <span id="continue" class="a-button a-button-span12 a-button-primary" data-v-776b4554=""><span class="a-button-inner" data-v-776b4554=""><input autocomplete="off" id="continue" @click="login()" class="a-button-input" type="submit" aria-labelledby="continue-announce" data-v-776b4554=""><span id="continue-announce" class="a-button-text" aria-hidden="true" data-v-776b4554=""> Continue </span></span></span> 
        </div>
      </div>
    </div>
    <div class="a-divider a-divider-break mt-2" data-v-776b4554="">
      <h5 data-v-776b4554=""> Don't have an account? </h5>
    </div>
    <span id="auth-create-account-link" class="a-button a-button-span12 a-button-base" data-v-776b4554="">
      <span class="a-button-inner" data-v-776b4554="">
        <router-link to="register"  data-toggle="modal" data-target="#registerform" class="a-button-text" data-v-776b4554="">Create your account</router-link>
      </span>
    </span>
  </div>
</template>
<script> 
import {HTTP} from '../../../http-common'
import {mapGetters} from 'vuex'
export default {
   name: 'login',
   data() {
      return {
         phone : '',
         password : '', 
         errors: {
            phone:'',
            password : ''
         },
      }
   },
    mounted(){
      if(this.$route.params.reload){
         location.reload();
      }
      this.$refs.focus.focus();
    },
    computed :{
          ...mapGetters({ 
              domainProfile : 'domain/profile', 
          }),  
    },
   methods: {
      login(){
         this.errors = {},
         HTTP.post(`login`,{
            phone : this.phone
         }).then(({data}) => {
            if(!data.errors){
               this.$router.push({
                 name: 'LoginPassword',
                 query: {
                   userId : data.userId, 
                   isPhone : data.is_phone, 
                   phone : data.phone, 
               }
            });
            }else{
               this.errors = data.errors;
            }
         })
      }, 
      // loginWithOtp(){
      //    this.errors = {},
      //    HTTP.post(`login-with-otp`,{
      //       phone : this.phone
      //    }).then(({data}) => { 
      //       if(!data.errors){
      //          this.$router.push({
      //            name: 'LoginOtp',
      //            query: {
      //              userId : data.userId, 
      //              regId : data.regId, 
      //          }
      //       });
      //       }else{
      //          this.errors = data.errors;
      //       }
      //    })
      // },  
      // loginWithPassword() {
      //    this.errors = {},
      //    HTTP.post(`login-with-password`,{
      //       phone : this.phone,
      //       password : this.password
      //    }).then(({data}) => {
      //      if(!data.errors){
      //                this.$store.dispatch('auth/login',data)
      //                   this.$root.refresh = true;
      //                this.$router.push({name : 'Dashboard',param : {reload : true}});
      //       }else{
      //          this.errors = data.errors;
      //       }
      //    })
      // },   
   }
}
</script>
