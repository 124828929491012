import {HTTP} from '../http-common'
export default {
    namespaced: true,
    state: {
        profile :{
            primary_logo :{
                path : ''
            },
            phone_country_code:{
                phonecode :''
            },
            primary_address:{
                city : '',
                state : '',
                country : '',
            },
            nonCategorizedPages : []
        } , 
    },
    getters: {
        profile(state) {
            return state.profile;
        },  
        nonCategorizedPages(state) {
            return state.nonCategorizedPages;
        },  
    }, 
    mutations: {   
    },
    actions: { 
        async fetchProfile({dispatch,state},context){ 
                return await HTTP.get('store-profile')
                                .then(({data}) => {   
                                    state.profile = data.profile; 
                                    state.nonCategorizedPages = data.nonCategorizedPages; 
                                })
            }
        },   
    } 