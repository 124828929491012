import {HTTP} from '../http-common'
export default {
    namespaced: true,
    state: {
        token : localStorage.getItem('authToken') ?? false,
        authUser : false,
        countryCodes : false,
        countryCode : false,
    },
    getters: {
        token(state) {
            return state.token;
        }, 
        authUser(state) {
            return state.authUser;
        },
        countryCodes(state){
            return state.countryCodes;
        },
        countryCode(state){
            return state.countryCode;
        }
    },
    mutations: {  
      setToken(state,payload){
          state.token=payload;
      }, 
      setCountryCodes(state,payload){
          state.countryCodes = payload;
      },
      setCountryCode(state,payload){
          state.countryCode=payload;
      },  
    },
    actions: {
         login(context,data){
            localStorage.setItem('authToken',data.token);
            localStorage.setItem('authUser', JSON.stringify(data.user));
            context.state.token = data.token;
            context.state.authUser = data.user;

        },
         authUser({dispatch,state},context){
            if(localStorage.getItem('authToken')){
                return  HTTP.post('auth-user')
                                .then(({data}) => {    
                                    state.authUser = data; 
                                }).catch(data => {
                                    setTimeout(() => {
                                        dispatch("authUser2")    
                                    }, 5000);
                                });
            }
        },
         authUser2({dispatch,state},context){
            if(localStorage.getItem('authToken')){
                return  HTTP.post('auth-user')
                                .then(({data}) => {   
                                    state.authUser = data; 
                                }).catch(data => { 
                                    dispatch('logout');
                                });
            }
        },
        logout(context){
            
            localStorage.removeItem('authToken');
            localStorage.removeItem('authUser');
          return HTTP.post('logout')
                .then(res=>{
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('authUser');
                    context.state.token = false;
                    context.state.authUser = false;
                });
        },   
    }
}