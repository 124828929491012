<template> 
    <div class="a-section a-spacing-base">
      <div class="a-box">
        <div class="a-box-inner a-padding-extra-large">
          <h1>Authentication required</h1>
          <div class="a-row a-spacing-none">
            <p><strong>{{ formattedPhone }} </strong> 
                  <router-link :to="{name : 'ForgotPassword'}"  id="auth-fpp-link-bottom" class="a-link-normal ml-2">
                    Change
                  </router-link>
            </p>
            <p>
              
            <template v-if="isPhone"> 
            We have sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification.
            </template>
            <template v-else> 
            We have sent a One Time Password (OTP) to the email account above. Please enter it to complete verification.
            </template>
              <!-- We have sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification. -->
            </p>
          </div>
          <form  @submit="event.preventDefault()"  method="post" novalidate="" class="auth-validate-form auth-real-time-validation a-spacing-none" data-fwcim-id="h6qrgJYL">
            <div class="a-section">
              <div class="a-section a-spacing-large">
                <div class="a-row">
                  <div class="a-column a-span5">
                    <label for="ap_password" class="a-form-label">
                    Enter OTP
                    </label>
                  </div>
                </div>
                <input autocomplete="off" ref="focus" type="password" maxlength="1024" id="ap_password" v-on:keyup.enter="otpVerify()" v-model="otp" class="a-input-text a-span12 auth-autofocus auth-required-field">
            <div id="auth-customerName-missing-alert" v-if="errors.otp" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
              <div class="a-box-inner a-alert-container">
                <i class="a-icon a-icon-alert"></i>
                <div class="a-alert-content">
                  {{errors.otp}}
                </div>
              </div>
            </div>
              </div>
              <div class="a-section">
                <span id="auth-signin-button" class="a-button a-button-span12 a-button-primary auth-disable-button-on-submit"><span class="a-button-inner">
                <input autocomplete="off" id="signInSubmit" class="a-button-input" @click="otpVerify()" aria-labelledby="auth-signin-button-announce">
                <span id="auth-signin-button-announce" class="a-button-text" aria-hidden="true">
                Sign in
                </span></span></span>
              </div>
              <div class="a-section">
                <div class="a-section a-spacing-none a-spacing-top-large a-text-center cvf-widget-section-js">
                  <a type="button" id="cvf-resend-link" class="a-link-normal cvf-widget-btn cvf-widget-link-resend cvf-widget-link-disable-target" @click="resendOtp()">
                    <i class="icon-reload"></i>
                    Resend OTP
                  </a>
                </div>
              </div>
              <div class="a-section" v-if="isPhone">
                <div class="a-section a-spacing-none a-spacing-top-large a-text-center cvf-widget-section-js">
                  <a type="button" id="cvf-resend-link" class="a-link-normal cvf-widget-btn cvf-widget-link-resend cvf-widget-link-disable-target" @click="sendVoiceOtp()">
                    <i class="icon-call-in"> </i>
                   Voice OTP
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div> 
</template>
<script>
import {HTTP} from '../../../http-common'
export default {
    name:'ForgotPasswordOtp',
      data (){
        return{ 
            otp : '', 
            phone : this.$route.query.phone, 
            errors: {
               otp : ''
            },
        }
    },
    mounted(){
      this.$refs.focus.focus();
    },
    computed : {
       formattedPhone(){
           if(Number(this.$route.query.isPhone)){
               return `${this.$route.query.phone}`;
           }
           return this.$route.query.phone;
       },
       isPhone(){
           if(Number(this.$route.query.isPhone)){
               return 1;
           }
           return 0;
       },
    },
    methods:{ 
           async otpVerify(){
              this.errors = {},
              HTTP.post(`forgot-password-otp-submit`,{
                 userId : this.$route.query.userId,
                 regId : this.$route.query.regId,
                 otp : this.otp
              }).then(({data}) => {
                 if(!data.errors){ 
                    this.$router.push({
                        name: 'ForgotPasswordCreate',
                        query: {
                          userId : data.userId,
                          verification_token : data.verification_token,
                          isPhone : this.$route.query.isPhone,
                    }
                 });
                 }else{
                    this.errors = data.errors;
                 }
              })
        }, 
           async sendVoiceOtp(){ 
              HTTP.post(`forgot-password-voice-otp`,{
                 userId : this.$route.query.userId, 
                 regId : this.$route.query.regId, 
              }).then(({data}) => {
                //  if(!data.success){
                //     alert('failed');
                //   }else{ 
                //      alert('Success');
                //  }
              })
        }, 
           async resendOtp(){ 
              HTTP.post(`forgot-password-resend-otp`,{
                 userId : this.$route.query.userId, 
                 regId : this.$route.query.regId, 
              }).then(({data}) => {
                //  if(!data.success){
                //     alert('failed');
                //   }else{ 
                //      alert('Success');
                //  }
              })
        }, 
    }, 
}
</script>
