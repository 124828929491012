<template>
     <div class="row"> 
               <div class="col-md-12">
                  <h3>Your Addresses
                  </h3> 
                     <div class="row" style="font-size: 14px; line-height: 18px;">
                        <div class="col-md-3 mt-2">
                           <router-link  class="a-box p-4  order-info text-center address-box" style="border-style: dashed;" :to="{name : 'AddressCreate'}" > 
                                <h2 class="mt-5"><i class="fa fa-plus"></i></h2>
                                <div>Add address</div> 
                           </router-link>
                     </div>

                        <div class="col-md-3 mt-2" v-for="address in all" :key="address.id">
                           <div class="a-box p-4  order-info address-box"> 
                              <div class="font-weight-bold mb-2">
                                 {{ address.name ?? "" }}  <br> 
                              </div>
                              <div>  
                                  
                                 {{ address.address }} <br>
                                 {{ address.locality }} <br>
                                 {{ address.landmark }} <br>
                                 {{ address.city.name }}, {{ address.state.name }} {{ address.pincode }} <br>
                                 {{ address.country.name }} <br>
                                 Phone: {{ address.phone }} <br>
                                 <template v-if="address.address_type_id !== 1">
                                 <router-link  class="btn btn-sm mt-3 font-weight-light" :to="{name : 'AddressEdit',query:{addressId :address.id}}">Edit</router-link>  
                                 <button class="btn btn-sm mt-3 font-weight-light" @click="setPrimaryAddress(address.id)">Set as default</button>
                                 <button class="btn btn-sm mt-3 font-weight-light" @click="remove(address.id)">Delete</button>
                                 </template>
                                 <button v-else class="btn btn-sm mt-3 font-weight-light">Primary</button>
                              </div>
                           </div> 
                     </div> 
                  </div> 
               </div>
               </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
export default {
   name : 'All',
   computed : {
      ...mapGetters({
         'all' : 'address/all'
      })
   },
   methods : {
      ...mapActions({
          removeAddress : 'address/removeAddress', 
      }),
      remove(id){
         this.removeAddress(id);
      }, 
      setPrimaryAddress(addressId){ 
               this.$store.dispatch('address/setPrimaryAddress',addressId)
                     .then((data) => { 
                        if(data.errors){
                           this.errors = data.errors;
                        }else{
                           this.$router.push({name : 'Address'});
                        }
               }); 
      }
   }
}
</script>