import {HTTP} from '../http-common'
export default {
    namespaced: true,
        state: {
        token:'', 
        user: '',  
    },
    getters: {
        token(state) {
            return state.token;
        }, 
        user(state) {
            return JSON.parse(state.user);
        },
    },
    mutations: {  
      setToken(state,payload){
          state.token=payload;
        },

       setUser(state, payload){

          state.user=JSON.parse(payload); 
       },
      setAuthLogin(state,payload){
         
          state.token=payload;
      }
    },
    actions: {
             async updatePassword(context,data){
              return await HTTP.post('customer-update-password',{
                 'currentPassword':data.currentPassword,
                'newPassword':data.newPassword,
                'retypePassword':data.retypePassword,
                }).then(
                       response => { 
                           return  response.data;
                       }
                )
        },
    async login(context,phone) { 
            return await HTTP.post('login', {
               'phone':phone, 
                })
               .then(response => { 
                  return response.data;
                   });   
    },
     
    async userUpdate(context,userData){
            return await HTTP.post(`user-update`, {
                'name': userData.name,
                'email': userData.email,
                'phone': userData.phone,
              })
                .then(response => {
                 return response.data;
                });   

        },
        async userRegisteration(context,data){
              return await HTTP.post('user-register',{
               'name':data.name,
               'contact':data.contact,
               'password':data.password,
               'password_confirmation':data.passwordConfirmation,
               }).then(
                      response => {
                        return  response.data;
                      }
               )
            },

        
        async registerationVerification(context,data){
            return await HTTP.post('user-verify-otp',{
                    'contact':data.contact,
                'otp':data.otp,
                }).then(
                       response => {
                           return  response.data;
                       }
                )
    },
     async loginWithPassword(context,data) {
          return await HTTP.post('login-with-password',{
               
                'contact':data.contact,
                'password':data.password,
                }).then(
                       response => {
                           return  response.data;
                       }
                )

        },
            async loginWithOtp(context,data) {
            return await HTTP.post('login',{
                       'contact':data.contact,
                  }).then(
                         response => {
                             return  response.data;
                         }
                  )
  
          },
         async loginVerification(context,data){
            return await HTTP.post('login-otp-verify',{
               'contact':data.contact,
                'otp':data.otp,
                }).then(
                       response => {
                           return  response.data;
                       }
                )
        },
            
        async forgotPassword(context,email){
               return await HTTP.post('forgot-password',{
                'email':email,
            }).then(response =>{
                     return response.data;
                             })
    },
              async logout(context){
          return   HTTP.post('logout')
                .then(response=>{ 
                       if(response.status){
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('authUser');
                        context.commit('setToken','');
                        context.commit('setUser',JSON.stringify(''));
                        context.commit('setAuthLogin',false);
                        return response;
                       }
                })


        }



    }
}