import { createStore} from 'vuex'
import auth from './auth'
import customer from './customer'
import address from './address' 
import cart from './cart'
import checkout from './checkout'
import order from './order'
import frontend from './frontend' 
import domain from './domain'
const store = createStore({
    modules : {auth,address,frontend,cart,order,checkout,customer,domain},
    
  
})

export default store;