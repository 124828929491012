import { HTTP } from '../http-common'
export default {
    namespaced: true,
    state: {
        all: [],
        countries: [],
        countryCodes: [],
        states: {},
        cities: {},
        editAddress : null,
    },
    getters: {
        all(state) {
            return state.all;
        },
        getCountries(state) {
            return state.countries;
        },
        getCountryCodes(state) {
            return state.countryCodes;
        },
        getStates(state) {
            return state.states;
        },
        getCities(state) {
            return state.cities;
        },
        getPrimaryAddress(state){
            return   state.primaryAddress; 
        }
    },
    mutations: {
        setAll(state, data) {
            state.all = data;
        },  
        setCountries(state,data){
            state.countries = data;
        },
        setCountryCodes(state,data){
            state.countryCodes = data;
        },
        setStates(state,data){
            state.states=data;
        },
        setCities(state,data){
            state.cities=data;
        }
  },
    actions: {
        async all(context) { 
            return await HTTP.post(`address`).then(response => {
                context.commit('setAll', response.data);
            }); 
        },
        async getCountries(context) {
           return await HTTP.get('countries').then(res => {
                context.commit('setCountries', res.data);
               });
        },
        async fetchCountryCodes(context) {
           return await HTTP.get('country-codes').then(res => {
                context.commit('setCountryCodes', res.data);
               });
        },
        async getStates(context, countryId) {
            return await HTTP.get(`states/${countryId}`).then(res => {
                context.commit('setStates', res.data);
            });
        },
        async getCities(context, stateId) {
           return await HTTP.get(`cities/${stateId}`).then(res => {
            context.commit('setCities', res.data);
            });
        },
        async saveAddress({dispatch},address) { 
               return await HTTP.post('address-save', {
                    address : address
                })
                .then(({data}) => { 
                    dispatch('all');
                    return data;
                });   
        },
        async updateAddress({dispatch},address) { 
            
               return await HTTP.post(`address-update`, {
                        address : address
                    })
                    .then(({data}) => { 
                        dispatch('all');
                        return data;
                    });      
        },
        async removeAddress({dispatch}, addressId) {
            HTTP.post(`address-delete`,{
                addressId : addressId
            }).then(res => {
                dispatch('all');
            });
        },
        async setPrimaryAddress({dispatch},addressId){
            return await HTTP.post(`address-set-primary`,{
                addressId : addressId
            }).then(response => {
                dispatch('all');
            });     
        }
    
}
}