<template>
     <div class="row"> 
               <div class="col-md-9">
                  
                               <router-link class="btn btn-inverse" :to="{name:'Order'}">  <i class="icon-arrow-left"></i> Back </router-link>  
                  <div class="card">
                     <div class="modal-header" style="background-color: #f7f7f7;">
                        <h3 style="color: #555 !important;" class="mb-0 text-center">
                           Order Details
                        </h3>
                       
                           <div class="" v-if="order.order_dispatch"> 
                              <router-link  class="btn btn-primary float-right"  :to="{name : 'CreateReturn',params :{orderId : order?.id ?? '0'}}">Return</router-link> 
                           </div> 
                     </div>
                     <div class="row card-body"  style="font-size: 14px; line-height: 20px;">
                        <div class="col-md-4">
                           <span class="font-weight-bold">Placed On</span> : {{ order.placed_on }} <br>
                           <span class="font-weight-bold"> Order#</span> {{ order.id }}  <br>
                           <!-- <span class="font-weight-bold">Delivered :</span> NULL <br> -->
                           <span class="font-weight-bold">Qty. :</span> {{ order.total_qty }}
                        </div>
                        <div class="col-md-4">
                           <div class="  mb-2">
                              <span class="font-weight-bold">Shipping Address </span> 
                              </div>
                              <div v-if="order.shipping_address2">   
                              {{ order.shipping_address2.name  }}   <br>  
                              {{ order.shipping_address2.address  }}   <br>  
                              {{ order.shipping_address2.locality  }}   <br>  
                              {{ order.shipping_address2.landmark  }}   <br>  
                              {{ order.shipping_address2.city.name  }},      
                              {{ order.shipping_address2.state.name  }},     <br>   
                              {{ order.shipping_address2.pincode  }}  
                              {{ order.shipping_address2.country.name  }}   <br>   
                           </div>
                        </div>
                        <div class="col-md-4">
                           <div class="  mb-2">
                              <span class="font-weight-bold">Billing Address </span> 
                              </div>
                              <div v-if="order.billing_address2">   
                              {{ order.billing_address2.name  }}   <br>  
                              {{ order.billing_address2.address  }}   <br>  
                              {{ order.billing_address2.locality  }}   <br>  
                              {{ order.billing_address2.landmark  }}   <br>  
                              {{ order.billing_address2.city.name  }},      
                              {{ order.billing_address2.state.name  }},     <br>   
                              {{ order.billing_address2.pincode  }}  
                              {{ order.billing_address2.country.name  }}   <br>   
                           </div>
                        </div> 
                     </div>
                     <div class="a-box order-info"> 
                           <div class="row card-body"  v-for="(detail, index) in order.details" :key="index">
                              <div class="col-md-8">
                                 <div class="row box-inne">
                                      <div class="col-md-2"> 
                                       <a v-if="detail?.item"> 
                                 <img :src="detail.item.certificate_image ? detail.item.certificate_image.path :'http://placehold.jp/150x150.png'"   >
                                 </a>
                                </div>
                                <div class="col-md-10">
                                   <span class="a-color-secondary value" style="font-size: 14px;"> {{ detail.item.iname }}
                                   </span>
                                   <div class="a-row a-size-small" style="font-size: 14px; line-height: 18px;">SKU : {{ domainProfile.id }}{{ detail.item.gin }}</div>
                                </div>
                                 </div>
                              </div>
                              <div class="col-md-4" style="font-size: 14px; line-height: 18px;">
                                 <span class="a-color-secondary value" style="font-size: 14px;"> 
                                 Amount :
                                 </span>
                                 <span class="text-right">
                                
                                 {{ detail.product_amount }}
                                 </span>
                                 <br>
                                 <span class="a-color-secondary value" style="font-size: 14px;"> 
                                 Shipping :
                                 </span>
                                 <span class="text-right">
                                 00.00 
                                 </span>
                                 <br>
                                 <span class="a-color-secondary value" style="font-size: 14px;"> 
                                 Total :
                                 </span>
                                 <span class="text-right">
                                 {{ detail.total_amount }}
                                 </span>
                                 <br> 
                              </div>
                           </div> 
                     </div>  
                  <div class="a-box p-4   order-info">
                     <div class="row" style="font-size: 14px; line-height: 18px;">
                        <div class="col-md-6 mb-sm-2">
                           <div class="font-weight-bold mb-2">
                              Payment Details
                           </div>
                           <template v-if="order?.payment.id">
                           <div class="row">
                              <div class="col-md-8">
                                 Reference Number#
                              </div>
                              <div class="col-md-4 text-right">
                                {{order.payment.razorpay_payment_id}}
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-md-8">
                                 Amount :
                              </div>
                              <div class="col-md-4 text-right">
                                Rs.{{order.payment.amount}}
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-md-8">
                                 Status
                              </div>
                              <div class="col-md-4 text-right">
                                 Success
                              </div>
                           </div>
                           </template> 
                              <template v-else>
                           <div class="card-header">Total({{ order.total_qty }} items) : Rs.{{order.total_amount ? order.total_amount : 0 }} 
                              </div>
                           <div class=""> 
                               <button type="button" class="btn btn-primary float-right"  @click="retryPayment()">Retry Payment</button> 
                           </div> 
                              </template>
                        </div>
                        
                        <div class="col-md-1 mb-sm-2">
                        </div>
                        <div class="col-md-5 mb-sm-2">
                           <div class="font-weight-bold mb-2">
                              Order Summary
                           </div>
                           <div class="row">
                              <div class="col-md-8">
                                 Item(s) Subtotal:
                              </div>
                              <div class="col-md-4 text-right">
                                Rs.{{order.total_amount}}
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-md-8">
                                 Shipping:
                              </div>
                              <div class="col-md-4 text-right">
                                Rs.00.00
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-md-8">
                                 Total:
                              </div>
                              <div class="col-md-4 text-right">
                                 Rs.{{ order.total_amount }}
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-md-8">
                                 Promotion Applied:
                              </div>
                              <div class="col-md-4 text-right">
                               Rs.00.00
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-md-8 font-weight-bold">
                                 Grand Total:
                              </div>
                              <div class="col-md-4 text-right">
                                 Rs.{{ order.total_amount }}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <template v-if="order?.returns">
                     <div class="a-box p-4 order-info" v-for="(orderReturn,index) in order?.returns" :key="index"> 
                     <div class="row" style="font-size: 14px; line-height: 18px;">
                        <div class="col-md-12 mb-sm-2">
                           <div class="font-weight-bold mb-2">
                              Return Items
                           </div>
                           </div>
                           </div>
                           <div class="row card-body"  v-for="(detail, index) in orderReturn?.details" :key="index">
                              <div class="col-md-8">
                                 <div class="row box-inne">
                                      <div class="col-md-2"> 
                                       <a v-if="detail?.item"> 
                                 <img :src="detail.item.certificate_image ? detail.item.certificate_image.path :'http://placehold.jp/150x150.png'"   >
                                 </a>
                                </div>
                                <div class="col-md-10">
                                   <span class="a-color-secondary value" style="font-size: 14px;"> {{ detail.item.iname }}
                                   </span>
                                   <div class="a-row a-size-small" style="font-size: 14px; line-height: 18px;">SKU : {{ domainProfile.id }}{{ detail.item.gin }}</div>
                                </div>
                                 </div>
                              </div>
                              <div class="col-md-4" style="font-size: 14px; line-height: 18px;">
                                 <span class="a-color-secondary value" style="font-size: 14px;"> 
                                 Amount :
                                 </span>
                                 <span class="text-right">
                                
                                 {{ detail.product_amount }}
                                 </span>
                                 <br>
                                 <span class="a-color-secondary value" style="font-size: 14px;"> 
                                 Shipping :
                                 </span>
                                 <span class="text-right">
                                 00.00 
                                 </span>
                                 <br>
                                 <span class="a-color-secondary value" style="font-size: 14px;"> 
                                 Total :
                                 </span>
                                 <span class="text-right">
                                 {{ detail.total_amount }}
                                 </span>
                                 <br> 
                              </div>
                           </div> 
                     <div class="row" style="font-size: 14px; line-height: 18px;"> 
                           <div class="col-md-12">
                           <span class="font-weight-bold">Requested On</span> : {{ orderReturn.placed_on }} <br>
                           <span class="font-weight-bold"> Return#</span> {{ orderReturn.id }}  <br>
                           <!-- <span class="font-weight-bold">Delivered :</span> NULL <br> -->
                           <span class="font-weight-bold">Qty. :</span> {{ orderReturn.total_qty }} <br>
                           <span class="font-weight-bold">Status :</span> {{ orderReturn.status_label }} <br>
                           <span class="font-weight-bold">Reason :</span> {{ orderReturn.reason }} <br>
                           <span class="font-weight-bold">Comment :</span> {{ orderReturn.comment }} <br> 
                           <span class="font-weight-bold">Attached Images :</span> <br>
                           <div v-for="image in orderReturn.images" :key="image.id">
      <img width="200" :src="image.path" alt="Attached Image">
    </div> <br>
             <template v-if="orderReturn?.status_label == 'Picked Up'">

                           <span class="font-weight-bold">Courier Partner :</span> {{ orderReturn.courier_partner }} <br>
                           <span class="font-weight-bold">Traking Number :</span> {{ orderReturn.tracking_number }} <br> 
             </template>
                        </div>
                        <div class="col-md-12"  v-if="isReadyToEnableTrackingDetailsForm(orderReturn.status_label)">
 <span class="font-weight-bold">Add Tracking Details :</span> <br> 
                                                   
  <div>  
        <label for="reason">Courier Partner :</label>
        <select id="reason" v-model="courierPartner">
          <option value="Fedex">Fedex</option>
          <option value="Delhivery">Delhivery</option> 
        </select>
      </div>
      <div>
        <label for="trackingNumber">Traking Number:</label>
        <textarea id="trackingNumber" v-model="trackingNumber"></textarea>
      </div>
                               <button type="button" class="btn btn-primary float-right"  @click="submitTrackingDetails(orderReturn.id)">  Submit Tracking Details</button> 
                        </div> 
                     </div> 
                        </div> 
                  </template>   
                  </div>
               </div>
               </div>
</template>
<script>
import {HTTP} from '../../../../http-common'
import {mapGetters,mapActions} from 'vuex'
export default {
   name : 'OrderView',
   data(){
    return{ 
      courierPartner: '',
      trackingNumber: '', 
      approved : false
    }
   },
   computed : {
      ...mapGetters({
         'order' : 'order/getOrder',
         'domainProfile' : 'domain/profile',
      }), 
   },
   methods : {
      ...mapActions({
         fetchOrder : 'order/fetchOrder'
      }), 
     async  submitTrackingDetails(id){ 

         await HTTP.post('return-request-tracking-details-submit',{
            returnId : id,
            courierPartner: this.courierPartner,
            trackingNumber: this.trackingNumber, 

          }).then(()=>  this.fetchOrder(this.$route.params.id)); 
      },
      retryPayment(){
          HTTP.post('retry-pay',{
             orderId : this.$route.params.id, 
          }).then(({data})=> {
            
               this.$router.push({
                     name: 'Pay',
                     query : 
                        data 
                     ,
               });
          });
      },
      isReadyToEnableTrackingDetailsForm(label){ 
         return label == 'Approved' ? true : false;

      }
   },
   mounted(){
      this.fetchOrder(this.$route.params.id);
   }
}
</script>