<template>
   <div class="container">
      <div class="row">
         <div class="col-lg-4 col-md-4">
            <div class="single-banner mb--30">
               <div class="banner-bg"> <a href="products/buy-office-chairs-online-delhi.html"><img src="../../../../public/assets/images/banner1.jpg" alt="GET UPTO 50% Off"></a> </div>
               <div class="banner-contet">
               </div>
            </div>
         </div>
         <div class="col-lg-4 col-md-4">
            <div class="single-banner  mb--30">
               <div class="banner-bg"> <a href="product/buy-office-chairs-online-delhi.html"><img src="../../../../public/assets/images/banner2.jpg" alt="GET UPTO 55% Off"></a> </div>
               <div class="banner-contet">
               </div>
            </div>
         </div>
         <div class="col-lg-4 col-md-4">
            <div class="single-banner  mb--30">
               <div class="banner-bg"> <a href="product/buy-office-chairs-online-delhi.html">
               <img src="../../../../public/assets/images/banner3.jpg" alt="GET UPTO 55% Off"> 
               </a> </div>
               <div class="banner-contet">
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   export default {
       name:'Banner', 
   }
</script>