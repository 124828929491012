<template>
   <div>
      <div class="container commonBottomMargin "> 
         <div class="row justify-content-center">
            <div class="col-lg-6 col-md-3 col-sm-6 col-12 text-center mt-2">
               <img :src="productAttributes?.primary_image ?'https://erp44.com/public/admin/product-images/'+productAttributes.primary_image.filename:'http://placehold.jp/150x150.png'" class="mt-2">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt-3 ">
               <h3 class="mt-4">{{ productAttributes.alias ?? '' }}</h3>
               <h6>Available in almost every color of the rainbow, the sapphire is truly an iconic gem.<br> In ancient times, its stunning hues catapulted the stone’s position<br>and made it the pride of kings, queens, and aristocrats across the
                  world.
                  <br> Available in almost every color of the rainbow, the sapphire is truly an iconic gem.<br> In ancient times, its stunning hues catapulted the stone’s position<br>and made it the pride of kings, queens, and aristocrats across
                  the world.
               </h6>
            </div>
         </div>
      </div>
      <div class="container-fluid mt-5">
         <div class="row commonBottomMargin"> 
            <div class="col-md-3">
               <div class="py-2 border-bottom ml-3 rev_c1" style="line-height: 16px;">
                  <h5 class="mb-1 font-weight-bold">Grades</h5>
                  <template v-for="grade in productAttributes.grade" :key="grade.id">
                  <div v-if="grade.product_stocks_count">
                     <input type="checkbox"  v-model="filter.grades"  :value="grade.id">

                     
                     <!-- <label for="artisan" class="filter-label"> -->
                        {{ grade.alias }} ({{ grade.product_stocks_count }}) 
                        <!-- </label> -->
                  </div>
                  </template>
               </div>
               <div class="border-bottom ml-3 rev_c1" style="line-height: 16px;" >
                  <div class="form-group" v-if="selectedCarat.status == 0" >
                     <Slider 
                        v-model="filter.selectedRatti.range" 
                        :min="minRatti"
                        :max="maxRatti"
                        />
                  </div>
                  <h5 class="mb-1 font-weight-bold">Ratti <input type="radio" v-model="selectedCarat.status" :value="0"> </h5>
               </div>
               <div class="border-bottom ml-3  rev_c1" style="line-height: 16px;">
                  <h5 class="mb-1 font-weight-bold">Weight/Carat <input type="radio" v-model="selectedCarat.status" :value="1"> </h5>
                  <!-- <div class="form-group"> -->
                     <input type="number"  :disabled='selectedCarat.status == 0' v-model.lazy="selectedCarat.min" placeholder="From">
                     <input class="ml-0 mt-1" type="number"  :disabled='selectedCarat.status == 0' v-model.lazy="selectedCarat.max" placeholder="To">
                     <button class="ml-2 mt-1 btn btn-sm btn-secondary m-0 p-0 px-1" :disabled='selectedCarat.status == 0'  @click="fetchItemsByCarat()">OK</button>
                  <!-- </div> -->
               </div>
               <div class="border-bottom ml-3 rev_c1" style="line-height: 16px;">
                  <h5 class="mb-1 font-weight-bold">Colors</h5>
                  
                  <template v-for="color in productAttributes.colors" :key="color.id">
                  <div v-if="color.product_stocks_count">
                     <input type="checkbox"  v-model="filter.colors"  :value="color.id">
                     <!-- <label for="artisan" class="filter-label"> -->
                        {{ color.alias }} ({{ color.product_stocks_count }}) 
                        <!-- </label> -->
                  </div>
                  </template> 
               </div>
               <div class="border-bottom ml-3 rev_c1" style="line-height: 16px;">
                  <h5 class="mb-1 font-weight-bold">Clarities</h5>
                  <template  v-for="clarity in productAttributes.clarity" :key="clarity.id">
                  <div v-if="clarity.product_stocks_count">
                     <input type="checkbox"  v-model="filter.clarities"  :value="clarity.id">
                     <!-- <label for="artisan" class="filter-label"> -->
                        {{ clarity.alias }} ({{ clarity.product_stocks_count }}) 
                        <!-- </label> -->
                  </div>
                        
                  </template>
               </div>
               <div class="border-bottom ml-3 rev_c1" style="line-height: 16px;">
                  <h5 class="mb-1 font-weight-bold">Shapes</h5>
                  <template v-for="shape in productAttributes.shape" :key="shape.id">
                  <div v-if="shape.product_stocks_count">
                     <input type="checkbox"  v-model="filter.shapes"  :value="shape.id">
                     <!-- <label for="artisan" class="filter-label"> -->
                        {{ shape.alias }} ({{ shape.product_stocks_count }}) 
                        <!-- </label> -->
                  </div>
                        
                  </template>
               </div>
               <div class="border-bottom ml-3 rev_c1" style="line-height: 16px;">
                  <h5 class="mb-1 font-weight-bold">Origins</h5>
                  <template v-for="origin in productAttributes.origin" :key="origin.id">
                        
                  <div v-if="origin.product_stocks_count">
                     <input type="checkbox"  v-model="filter.origins"  :value="origin.id">
                     <!-- <label for="artisan" class="filter-label"> -->
                        {{ origin.alias }} ({{ origin.product_stocks_count }}) 
                        <!-- </label> -->
                  </div>
                  </template>
               </div>
               <div class="ml-3 mt-2">
                  <button @click="fetchItems()">Apply Filter</button>
               </div>
            </div>
            <div class="col-md-9"  ref="itemsElement">
               <div class="row">
                  <template v-if="items.data.length > 0">
                     <ItemListView v-for="item in items.data" :key="item.id" :item='item'></ItemListView>
            <template v-if="loading && items.data.length > 0">
  <InfiniteLoading @infinite="fetchItems" /> 
               </template>      
                  </template>
                  <template v-else>
                     <div class="justify-content-center">
                        <h1>No Products Found.</h1>
                     </div>
                  </template>
               </div>
               <!-- <div class="row" v-if="items.data.length > 0">
                  <div class="col-12">
                     <div class="paginatoin-area text-center">
                        <ul class="pagination"> 
                        </ul>
                     </div>
                  </div>
               </div> -->
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   import { mapActions, mapGetters } from 'vuex';
   import ItemListView from '../item/ListView.vue';
   import Slider from '@vueform/slider'
   import { HTTP } from '../../../http-common'
  import InfiniteLoading from "v3-infinite-loading";
   export default {
       name: 'Product',
       components : {
          InfiniteLoading,ItemListView, Slider 
       },
       data() {
         return {
            filter: {
               grades: [],
               ratti: [],
               colors: [],
               clarities: [],
               shapes: [],
               origins: [],
               product: this.$route.params.productId,
               selectedRatti: {
                   range : [0,105]
               },
          },
               selectedCarat :{ 
                     status : 0, 
                     min: '',
                     max: '',
               },
           loading : true,
   
            items: {
                data : {}
            },
            page : 0, 
            minRatti: 0,
            maxRatti: 0,
            isUpdatingFilter: false,
         }
       },  
       methods: {
       ...mapActions({ 
            fetchRattis: 'frontend/fetchRattis',
            fetchProductAttributes: 'frontend/fetchProductAttributes',
       }), 
        fetchItems() { 
          let loader = this.$loading.show({ 
                    canCancel: true,
                    onCancel: this.onCancel,
            });  
            this.page++; 
             HTTP.post(`product-items?page=${this.page}`,
               {
                filter : this.filter,
                selectedRatti : this.filter.selectedRatti,
                selectedCarat : this.selectedCarat,
               }
            ).then(({ data }) => {  
               if(this.page == 1){
                  this.items.data = data.data;
               }else{  
                     this.items.data = [...this.items.data, ...data.data]; 
               } 
               if(data.data.length !== 20){
                  this.loading = false;
               }else{
                  this.loading = true;
               }
            }).catch(() =>{
               // this.page = 1
               // this.fetchItems()
            })
            .finally(() => {
               loader.hide() ;
            });
            
            const routeParams = {
               filterGrades: this.filter.grades,
               // filterRatti: this.filter.ratti,
               filterColors: this.filter.colors,
               filterClarities: this.filter.clarities,
               filterShapes: this.filter.shapes,
               filterOrigins: this.filter.origins,
               // filterProduct: this.filter.product,
               filterSelectedRattiRange: this.filter.selectedRatti.range, 
               // filterSelectedRattiRangeTo: this.filter.selectedRatti.range[1],
               selectedCaratStatus: this.selectedCarat.status,
               selectedCaratMin: this.selectedCarat.min,
               selectedCaratMax: this.selectedCarat.max,
               };
               this.$router.replace({ query: routeParams });
       }, 
       fetchItemsByCarat(){

                  this.items.data = {};
                  this.page = 0;
                  this.fetchItems(); 
       },  
       },
   
       watch:{
           rattis(){
              if(this.isUpdatingFilter){
                 const values = Object.values(this.rattiStandards);
                 this.minRatti = parseInt(Math.min(...values)); 
                 this.maxRatti = parseInt(Math.max(...values)); 
              }
           }, 
         //   filter:{
         //      handler(filter){  
         //          if(this.isUpdatingFilter){ 
         //                this.items.data = {};
         //                this.page = 0;
         //                this.fetchItems();  
         //          }
         //      },
         //      deep:true
         //   },
         //   'selectedCarat.status' :{
         //      handler(filter){ 
         //       if(this.isUpdatingFilter){
         //             this.items.data = {};
         //             this.page = 0;
         //             this.fetchItems(); 
         //       }
         //      },
         //      deep:true
         //   }
       },
       computed: {
           ...mapGetters({ 
             productAttributes: 'frontend/getProductAttributes',  
             rattis: 'frontend/getRattis',
             products : 'frontend/getProducts',
           }),
           rattiStandards(){
               return this.rattis.map(ratti => ratti.rati_standard);
           },
           rattiSliderValue(){
               return [this.minRatti,this.maxRatti];
           },  
       },
       mounted() {
           this.fetchProductAttributes(this.$route.params.productId).then(() => { 
           });
           this.fetchRattis(); 
        this.isUpdatingFilter = false;
      const query = this.$route.query; 
      if ('filterGrades' in query) { 
        const filterGrades = Array.isArray(query.filterGrades) ? query.filterGrades : [query.filterGrades];
 
        this.filter.grades = filterGrades;
      }
      if ('filterColors' in query) { 
        const filterColors = Array.isArray(query.filterColors) ? query.filterColors : [query.filterColors];
 
        this.filter.colors = filterColors;
      } 
      if ('filterClarities' in query) { 
        const filterClarities = Array.isArray(query.filterClarities) ? query.filterClarities : [query.filterClarities];
 
        this.filter.clarities = filterClarities;
      } 
      if ('filterShapes' in query) { 
        const filterShapes = Array.isArray(query.filterShapes) ? query.filterShapes : [query.filterShapes];
 
        this.filter.shapes = filterShapes;
      }
      if ('filterOrigins' in query) { 
        const filterOrigins = Array.isArray(query.filterOrigins) ? query.filterOrigins : [query.filterOrigins];
 
        this.filter.origins = filterOrigins;
      }
      // if ('filterSelectedRattiRangeFrom' in query && 'filterSelectedRattiRangeTo' in query) { 
      //    this.filter.selectedRatti.range[0] = query.filterSelectedRattiRangeFrom
      //   ? query.filterSelectedRattiRangeFrom : 0; 
      //   console.log(query.filterSelectedRattiRangeTo)
      //    this.filter.selectedRatti.range[1] = query.filterSelectedRattiRangeTo
      //   ? query.filterSelectedRattiRangeTo : 105; 
      // } 
      // if ('selectedCaratMin' in query && 'selectedCaratMax' in query) { 
      //    console.log(query.selectedCaratMin)
      //   this.selectedCarat.min = query.selectedCaratMin || '';
      // this.selectedCarat.max = query.selectedCaratMax || ''; 
      // }
      // this.filter.grades = Array.isArray(query.filterGrades) ? query.filterGrades : [query.filterGrades];
      // this.filter.ratti = query.filterRatti || [];
      // this.filter.colors = query.filterColors || [];
      // this.filter.clarities = query.filterClarities || [];
      // this.filter.shapes = query.filterShapes || [];
      // this.filter.origins = query.filterOrigins || [];
      // this.filter.product = query.filterProduct || this.filter.product;
      this.filter.selectedRatti.range = query.filterSelectedRattiRange
        ? query.filterSelectedRattiRange.map(Number)
        : [0, 105];
 
      this.selectedCarat.status = query.selectedCaratStatus || 0;
      this.selectedCarat.min = query.selectedCaratMin || '';
      this.selectedCarat.max = query.selectedCaratMax || ''; 
      this.isUpdatingFilter = true;
      
           this.fetchItems(); 
       }, 
   }
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style scoped>


@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap');  

.rev_c1 { 
    /* width: calc(600px - 30px);  */
    padding: 10px 15px 10px 15px; 
    /* float: right;  */
    /* margin: 0 8px 25px 18px;  */
    margin-top:12px !important;
    border: 1px solid #3e3e3e6b; 
    border-radius: 5px; 
    /* position: relative;  */
    /* -webkit-box-shadow: 0px 2px 8px -2px rgba(179,173,179,1);  */
    /* -moz-box-shadow: 0px 2px 8px -2px rgba(179,173,179,1);  */
    /* box-shadow: 0px 2px 8px -2px rgba(179,173,179,1); */
}  



/* --------------------------------------------------- */
.header{
     position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
}


   *{
   box-sizing: border-box;
   }
   #search{
   outline: none;
   border: none;
   display: inline-block;
   }
   #burgundy{
   color: rgb(153, 40, 59);
   }
   #orange,select,.btn{
   color: orange;
   }
   .fa-search{
   font-size: 20px;
   padding: 10px;
   margin-bottom: 3px;
   padding-right: 20px;
   }
   .search-nav-item{
   height: 40px;
   }
   nav{
   padding: 0px 100px;
   }
   .fa-user-o,.fa-shopping-cart{
   font-size: 20px;
   padding: 4px;
   }
   .form-group{
   margin-bottom: 5px;
   }
   label{
   padding-left: 5px;
   }
   .form-group:last-child{
   margin-bottom: 0;
   }
   h6{
   margin-bottom: 0px;
   }
   #sort{
   outline: none;
   border: none;
   }
   .button-active:active{
   border: 1px solid orange !important;
   border-radius: 10px !important;
   background-color: #fff !important;
   }
   .btn:hover{
   color: #fff;
   background-color: orange;
   }
   .card-body{
   padding: 8px;
   }
   .sign{
   width: 25px;
   height: 25px;
   }
   .discount{
   border: 1px solid orange;
   border-radius: 5px;
   width: 65px;
   position: absolute;
   top: 2%;
   }
   @media(min-width:1200px){
   #search{
   width: 300px;
   padding: 5px;
   padding-left: 20px;
   }
   .search-nav-item{
   margin-left: 400px;
   width: 350px;
   }
   .fa-user-o{
   margin-left: 300px;
   }
   .text{
   display: none;
   }
   .fa-user-o,.fa-shopping-cart{
   font-size: 20px;
   padding-left: 20px;
   }
   #sidebar{
   width: 30%;
   padding: 20px;
   float: left;
   }
   #products{
   width: 70%;
   padding: 10px;
   margin: 0;
   float: right;
   }
   .card{
   width: 300px;
   height: 330px;
   border: none;
   }
   .card-img-top{
   width: 295px;
   height: 200px;
   border-radius: 10px;
   }
   del{
   padding-right: 2px;
   }
   .filter,#mobile-filter{
   display: none;
   }
   }
   @media(min-width:992px) and (max-width:1199px){
   #search{
   width: 300px;
   padding: 5px;
   padding-left: 20px;
   }
   .search-nav-item{
   margin-left: 200px;
   width: 350px;
   }
   .fa-user-o{
   margin-left: 160px;
   }
   .text{
   display: none;
   }
   #sidebar{
   width: 30%;
   padding: 20px;
   float: left;
   }
   #products{
   width: 70%;
   padding: 10px;
   margin: 0;
   float: right;
   }
   .card{
   width: 200px;
   height: 330px;
   border: none;
   }
   .card-img-top{
   width: 200px;
   height: 200px;
   border-radius: 10px;
   }
   .fa-plus,.fa-minus{
   font-size: 12px;
   }
   .sign{
   height: 25px;
   }
   .price{
   width: 99px;
   }
   .filter,#mobile-filter{
   display: none;
   }
   }
   @media(min-width:768px) and (max-width:991px){
   #search{
   width: 300px;
   padding: 5px;
   padding-left: 20px;
   }
   .search-nav-item{
   margin-left: 60px;
   width: 350px;
   }
   .fa-user-o{
   margin-left: 80px;
   }
   .text{
   display: none;
   }
   #sidebar{
   width: 35%;
   padding: 20px;
   float: left;
   }
   #products{
   width: 65%;
   padding: 10px;
   margin: 0;
   float: right;
   }
   .card{
   border: none;
   }
   .filter,#mobile-filter{
   display: none;
   }
   }
   @media(min-width:576px) and (max-width:767px){
   .text{
   display: none;
   }
   .search-nav-item{
   margin-left: 35px;
   width: 270px;
   }
   #search{
   width: 240px;
   padding: 5px;
   padding-left: 20px;
   }
   .fa-search{
   padding: 3px;
   font-size: 18px;
   }
   #sidebar{
   width: 40%;
   padding: 20px;
   float: left;
   }
   #products{
   width: 60%;
   padding: 10px;
   margin: 0;
   float: right;
   }
   .card{
   border: none;
   }
   #off{
   padding-left: 2px;
   }
   #sorting span,#res{
   font-size: 14px;
   }
   .filter,#mobile-filter{
   display: none;
   }
   }
   @media(max-width:575px){
   .search-nav-item{
   margin: 0;
   width: 100%;
   margin-top: 10px;
   }
   #search{
   width: 80%;
   padding-left: 10px;
   margin-top: 10px;
   padding-right: 10px;
   }
   .fa-search{
   padding: 10px;
   font-size: 18px;
   }
   #sidebar{
   display: none;
   }
   .filter{
   margin-left: 70%;
   margin-top: 2%;
   }
   #sorting,#res{
   font-size: 12px;
   margin-top: 2%;
   }
   }
.b_item  { 
    min-height: 115px; 
    padding: 24px 24px 5px 24px; 
    display: inline-block; 
    /* width: calc(25% - 24px);  */ 
    margin: 0 12.5px 25px 12.5px; 
    -webkit-box-shadow: 0px 0px 8px -2px rgb(179 173 179); 
    -moz-box-shadow: 0px 0px 8px -2px rgba(179,173,179,1); 
    box-shadow: 0px 0px 8px -2px rgb(104 102 104);
     transition: transform 0.3s ease;
}
</style>