<template>
   <div class="row">
     <div class="col-md-9">
                  <div class="container">
                     <div class="row">
                        <div class="col-12 mb-5 pb-5">
                           <form action="#" method="post" class="formnew">
                              <div class="modal-content">
                                 <div class="modal-header" style="background-color: #f7f7f7;">
                                    <h3 style="color: #555 !important;" class="mb-0">
                                       Edit Address
                                    </h3>
                                 </div>
                                 <div class="modal-body">
                                    <div class="row">
                                       <div class="col-md-4 col-sm-4">
                                          <div class="form-group">
                               <input type="text" name="reg-name" placeholder="Full Name *" class="form-control"  v-model="address.name">
                                             <i class="icon-user"></i>
                                              <span class="text-danger">{{errors.name}}</span>
                                          </div>
                                       </div>
                                       <div class="col-md-4 col-sm-4">
                                          <div class="form-group">
                                             <input type="text" name="reg-contact" placeholder="Mobile No. *" class="form-control" v-model="address.phone">
                                             <i class="icon-phone"></i>
                                             <span class="text-danger">{{errors.phone}}</span>
                                          </div>
                                       </div>
                                       <div class="col-md-4 col-sm-4">
                                          <div class="form-group">
                                             <input type="text" name="reg-pincode" placeholder="Delivery Address Label" class="form-control" required v-model="address.label" >
                                             <i class="icon-directions"></i>
                                             <span class="text-danger">{{errors.label}}</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-md-4 col-sm-4">
                                          <div class="form-group">
                                             <select class="custom-select form-control" name="country" v-model="address.countryId" v-on:change="getStates($event.target.value)">
                                             <option value="0">Select Country</option>
                              <option v-for="country in countries" v-bind:value="country.id" :key="country.id">
                                 {{country.name }}
                              </option>
                           </select>
                            <span class="text-danger">{{errors.countryId}}</span>
                                          </div>
                                       </div>
                                       <div class="col-md-4 col-sm-4">
                                          <div class="form-group">
                                             <select class="custom-select form-control" name="country"  v-model="address.stateId" v-on:change="getCities($event.target.value);">
                                              <option value="0">Select State</option>
                              <option v-for="state in states" v-bind:value="state.id" :key="state.id">
                                 {{state.name }}
                              </option>
                           </select>
                            <span class="text-danger">{{errors.stateId}}</span>
                                          </div>
                                       </div>
                                       <div class="col-md-4 col-sm-4">
                                          <div class="form-group">
                                             <select class="custom-select form-control" name="country"  v-model="address.cityId">
                                              <option value="0">Select City</option>
                              <option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                 {{city.name }}
                              </option>
                           </select>
                            <span class="text-danger">{{errors.cityId}}</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-md-12 col-sm-12">
                                          <div class="form-group">
                                             <textarea name="reg-address" placeholder="Address" rows="3" class="form-control" required v-model="address.address" ></textarea>
                                             <i class="icon-location-pin"></i>
                                               <span class="text-danger">{{errors.address}}</span>
                                          </div>
                                       </div>
                                       <div class="col-md-4 col-sm-4">
                                          <div class="form-group">
                                             <input type="text" name="reg-pincode" placeholder="Landmark" class="form-control" required v-model="address.landmark">
                                             <i class="icon-directions"></i>
                                              <span class="text-danger">{{errors.landmark}}</span>
                                          </div>
                                       </div>
                                       <div class="col-md-4 col-sm-4">
                                          <div class="form-group">
                                             <input type="text" name="reg-pincode" placeholder="Locality " class="form-control" required v-model="address.locality" >
                                             <i class="icon-directions"></i>
                                              <span class="text-danger">{{errors.locality}}</span>
                                          </div>
                                       </div>
                                       <div class="col-md-4 col-sm-4">
                                          <div class="form-group">
                                             <input type="text" name="reg-pincode" placeholder="Pin Code" class="form-control" required v-model="address.pincode">
                                             <i class="icon-directions"></i>
                                               <span class="text-danger">{{errors.pincode}}</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                <div class="modal-footer">
                                    <button type="button" name="submit" class="btn btn-block" @click.prevent="update()">Update</button>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  </div>
                  </div>
</template>
<script> 
   import {mapGetters,mapActions,mapState} from 'vuex'
   import {HTTP} from '../../../../http-common'
   export default {
       name : 'AddressEdit', 
        data(){
            return{
               address : {
                  id : '',
                  name: '',
                  phone:'',
                  countryId :0,
                  stateId :0,
                  cityId :0,
                  townId :0,
                  address :'',
                  locality :'',
                  landmark :'',
                  pincode :'',
                  label:''
               },
               errors:{
                  name:'',
                  phone:'',
                  countryId :'',
                  stateId :'',
                  cityId :'',
                  townId :'',
                  address :'',
                  locality :'',
                  landmark :'',
                  pincode :'',
                  label:''
               },
            }                                                         
        },
       computed:{
           ...mapState({
                editAddress : 'address/editAddress',
                
           }),
           ...mapGetters({
                countries :'address/getCountries',
                states : 'address/getStates',
                cities : 'address/getCities', 
           })
       },   
        created() {  
              this.edit(this.$route.query.addressId);
        },
       methods:{ 
         ...mapActions({
              getCountries : 'address/getCountries',
              getStates : 'address/getStates',
              getCities : 'address/getCities',
              getAddress : 'address/editAddress',
         }),
         edit(id){
             
              HTTP.post(`address-edit`,{
                addressId : id
            }).then(({data}) => { 
                let address = data;
               this.address.id = address.id;
               this.address.name = address.name;
               this.address.phone = address.phone;
               this.address.countryId = address.country_id;
               this.address.stateId = address.state_id;
               this.address.cityId = address.city_id;
               this.address.address = address.address;
               this.address.cityId = address.city_id;
               this.address.townId = address.city_id;
               this.address.locality = address.locality;
               this.address.landmark = address.landmark;
               this.address.pincode = address.pincode;
               this.address.label = address.label; 
            });  
         },
         update(){
            this.$store.dispatch('address/updateAddress',this.address)
                  .then((data) => { 
                        if(data.errors){
                           this.errors = data.errors;
                        }else{
                           this.$router.push({name : 'Address'});
                        }
                  });
         }
       },
       watch:{
           'address.countryId' : function(){
               this.getStates(this.address.countryId);
           },
           'address.stateId' : function(){
               this.getCities(this.address.stateId);
           }, 
       }
     }  
</script>