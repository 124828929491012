<template>
     <div class="row"> 
               <div class="col-md-9">
                  
                               <router-link class="btn btn-inverse" :to="{name:'Order'}">  <i class="icon-arrow-left"></i> Back </router-link>  
                  <div class="card">
                     <div class="modal-header" style="background-color: #f7f7f7;">
                        <h3 style="color: #555 !important;" class="mb-0 text-center">
                           Order Return Request

                        </h3>
                       
                           <div class=""> 
                               <!-- <button type="button" class="btn btn-primary float-right"  @click="return2()">Return</button>  -->
                           </div> 
                     </div>
                     <div class="row card-body"  style="font-size: 14px; line-height: 20px;">
                        <div class="col-md-4">
                           <span class="font-weight-bold">Placed On</span> : {{ order.placed_on }} <br>
                           <span class="font-weight-bold"> Order#</span> {{ order.id }}  <br>
                           <!-- <span class="font-weight-bold">Delivered :</span> NULL <br> -->
                           <span class="font-weight-bold">Qty. :</span> {{ order.total_qty }}
                        </div>
                        <div class="col-md-4">
                           <div class="  mb-2">
                              <span class="font-weight-bold">Shipping Address </span> 
                              </div>
                              <div v-if="order.shipping_address2">   
                              {{ order.shipping_address2.name  }}   <br>  
                              {{ order.shipping_address2.address  }}   <br>  
                              {{ order.shipping_address2.locality  }}   <br>  
                              {{ order.shipping_address2.landmark  }}   <br>  
                              {{ order.shipping_address2.city.name  }},      
                              {{ order.shipping_address2.state.name  }},     <br>   
                              {{ order.shipping_address2.pincode  }}  
                              {{ order.shipping_address2.country.name  }}   <br>   
                           </div>
                        </div>
                        <div class="col-md-4">
                           <div class="  mb-2">
                              <span class="font-weight-bold">Billing Address </span> 
                              </div>
                              <div v-if="order.billing_address2">   
                              {{ order.billing_address2.name  }}   <br>  
                              {{ order.billing_address2.address  }}   <br>  
                              {{ order.billing_address2.locality  }}   <br>  
                              {{ order.billing_address2.landmark  }}   <br>  
                              {{ order.billing_address2.city.name  }},      
                              {{ order.billing_address2.state.name  }},     <br>   
                              {{ order.billing_address2.pincode  }}  
                              {{ order.billing_address2.country.name  }}   <br>   
                           </div>
                        </div>
                        <!-- <div class="col-md-4">
                           <button class="btn">Open Tracking Link</button>
                        </div> -->
                     </div>
                     <div class="a-box order-info">
                        <div class=""> 
                           <div class="row card-body"  v-for="(detail, index) in order.details" :key="index">
                              <div class="col-md-8">
                                 <div class="row box-inne">
                                      <div class="col-md-1"> 
                                       <input type="checkbox" v-model="detailIds" :value="detail?.item.id">
                                </div>
                                      <div class="col-md-2"> 
                                       <a v-if="detail?.item"> 
                                 <img :src="detail.item.certificate_image ? detail.item.certificate_image.path :'http://placehold.jp/150x150.png'"   >
                                 </a>
                                </div>
                                <div class="col-md-9">
                                   <span class="a-color-secondary value" style="font-size: 14px;"> {{ detail.item.iname }}
                                   </span>
                                   <div class="a-row a-size-small" style="font-size: 14px; line-height: 18px;">SKU : {{ domainProfile.id }}{{ detail.item.gin }}</div>
                                </div>
                                 </div>
                              </div>
                              <div class="col-md-4" style="font-size: 14px; line-height: 18px;">
                                 <span class="a-color-secondary value" style="font-size: 14px;"> 
                                 Amount :
                                 </span>
                                 <span class="text-right">
                                
                                 {{ detail.product_amount }}
                                 </span>
                                 <br>
                                 <span class="a-color-secondary value" style="font-size: 14px;"> 
                                 Shipping :
                                 </span>
                                 <span class="text-right">
                                 00.00 
                                 </span>
                                 <br>
                                 <span class="a-color-secondary value" style="font-size: 14px;"> 
                                 Total :
                                 </span>
                                 <span class="text-right">
                                 {{ detail.total_amount }}
                                 </span>
                                 <br> 
                              </div>
                           </div>
                           <div class="row card-body">
                              <div class="col-12">
                                 
  <div>  
        <label for="reason">Reason for Return:</label>
        <select id="reason" v-model="selectedReason">
          <option value="defective">Defective Product</option>
          <option value="wrong">Wrong Product</option>
          <option value="not-satisfied">Not Satisfied</option>
          <!-- Add more options as needed -->
        </select>
      </div>
      <div>
        <label for="comment">Comment:</label>
        <textarea id="comment" v-model="comment"></textarea>
      </div>
      <div>
        <label for="images">Attach Images:</label>
        <input type="file" id="images" ref="imageInput" @change="handleImageUpload" multiple>
        <div class="image-previews">
          <img v-for="(preview, index) in imagePreviews" :src="preview" :key="index" alt="Image Preview">
        </div>
      </div>  
                              </div>
                              <div class="col-md-12">
                               <button type="button" class="btn btn-primary float-right"  @click="createReturnRequest()">Place Return Request</button> 
                              </div> 
                           </div>
                        </div>
                     </div> 
                  </div>  
               </div>
               </div>
</template>
<script>
import {HTTP} from '../../../../http-common'
import {mapGetters,mapActions} from 'vuex'
export default {
   name : 'CreateReturn',
   computed : {
      ...mapGetters({
         'order' : 'order/getOrder',
         'domainProfile' : 'domain/profile',
      })
   },
   data(){
    return{
       detailIds : [],
      selectedReason: '',
      comment: '',
      images: [],
      imagePreviews: [],
    }
   },
   methods : {
      ...mapActions({
         fetchOrder : 'order/fetchOrder'
      }), 
    handleImageUpload() {
      const input = this.$refs.imageInput;
      this.images = Array.from(input.files);
      this.imagePreviews = [];

      // Read and preview each image
      this.images.forEach((image) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreviews.push(e.target.result);
        };
        reader.readAsDataURL(image);
      });
    },
      createReturnRequest(){
      const formData = new FormData();
      formData.append('orderId', this.$route.params.orderId); 
      formData.append('reason', this.selectedReason);
      formData.append('comment', this.comment);
       this.images.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
      this.detailIds.forEach((id, index) => {
      formData.append(`detailIds[${index}]`, id);
    });
    });

          HTTP.post('return-request-create',formData).then(({data})=> {
            
               this.$router.push({
                     name: 'OrderView',
                     params : {
                        id : this.order.id
                     } ,
               });
          }); 
      }
   },
   mounted(){
      this.fetchOrder(this.$route.params.orderId);
   }
}
</script>