<template>
     <div class="main-content-wrap section-ptb cart-page pb-0">
      <div class="container">
         <div class="row justify-content-center">
            <div class="col-md-4 mb-5 pb-5">
               <form action="#" method="post" class="formnew">
                  <div class="modal-content">
                     <div class="modal-header" style="background-color: #f7f7f7;">
                        <h3 style="color: #555 !important;" class="mb-0 text-center">
                          Edit Profile
                        </h3> 
                     </div>
                     <div class="modal-body">
                        <div class="row">
                           <div class="col-12">
                              <div class="row">
                           <div class="col-12">
                              <div class="form-group">
                                 <input type="text" name="reg-name" placeholder="Full Name *" class="form-control" v-model="profile.name" autocomplete="off">
                                 <i class="icon-user"></i>
                                 <span class="text-danger">{{errors.name}}</span>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <input type="email" v-model="profile.email" placeholder="Email *"  class="form-control">
                                 <i class="icon-envelope-open"></i>
                                 <span class="text-danger">{{ errors.email }}</span>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
 <input type="date" v-model="profile.date_of_birth"    class="form-control">
                                 <!-- <i class="icon-envelope-open"></i>  -->
                                 <span class="text-danger">{{ errors.date_of_birth }}</span>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="form-group">
                                 <select class="custom-select form-control" v-model="profile.phone_country_code_id">
                                    <option value="0">Country Code</option>
                                    <option v-for="code in countryCodes" :key="code.id" :value="code.id">+{{ code.phonecode }}</option>
                                 </select>
                                 <span class="text-danger">{{errors.phone_country_code_id}}</span>
                              </div>
                           </div>
                           <div class="col-8">
                              <div class="form-group">
                                 <input type="text" name="reg-phone" placeholder="Mobile No. *" class="form-control" v-model="profile.phone" autocomplete="off">
                                 <i class="icon-phone"></i>
                                 <span class="text-danger">{{errors.phone}}</span>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <input type="radio" value="male" v-model="profile.gender"><span>Male</span>
                                 <input type="radio" value="female" v-model="profile.gender"><span>Female</span> 
                                 <span class="text-danger">{{errors.gender}}</span>
                              </div>
                           </div>
                           <!-- <div class="col-12">
                              <div class="form-group">
                                 <input type="password" name="reg-pass" placeholder="Password*" class="form-control"  v-model="authUser.password">
                                 <i class="icon-key"></i>
                                 <span class="text-danger">{{errors.password}}</span>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <input type="password" name="reg-cfpass" placeholder="Confirm Password *" class="form-control" v-model="authUser.password_confirmation">
                                 <i class="icon-key"></i>
                              </div>
                           </div> -->
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" name="submit" class="btn btn-block" @click.prevent="updateProfile()">Update</button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import {HTTP} from '../../../../http-common'
import {mapGetters,mapActions} from 'vuex' 
export default {
   name : 'EditProfile',
   components : {
      // Datepicker: 'VueDatePicker'
   },
   data () {
      return {
         profile : {
            name : '',
            email : '',
            phone : '',
            phone_country_code_id : '',
            date_of_birth : '',
            gender : '',
         },
         errors : {
            name : null,
            email : null,
            phone : null,
            phone_country_code_id : null,
            gender : null,
            date_of_birth : null,
         }
      }
   },
   computed : {
      ...mapGetters({
         domainProfile : 'domain/profile',
         authUser : 'auth/authUser',
         countryCodes: ['address/getCountryCodes'],
      })
   },
   methods : {
      ...mapActions({
         fetchCountryCodes :'address/fetchCountryCodes', 
         getAuthUser :'auth/authUser', 
      }), 
      updateProfile(){
          HTTP.post('update-profile',this.profile).then(({data})=> {
               if(!data.errors){
                  this.getAuthUser();
                  this.$router.push({
                     name: 'Dashboard',
                  });
               }else{
                  this.errors = data.errors;
               }
          });
      }
   },
   created(){
         this.fetchCountryCodes(); 
         this.profile.name =  this.authUser?.name;
         this.profile.email =  this.authUser?.email;
         this.profile.phone =  this.authUser?.phone;
         this.profile.phone_country_code_id =  this.authUser?.phone_country_code_id;
         this.profile.date_of_birth =  this.authUser?.date_of_birth;
         this.profile.gender =  this.authUser?.gender;
   },
   watch : {
      authUser(){
             this.profile.name =  this.authUser?.name;
             this.profile.email =  this.authUser?.email;
             this.profile.phone =  this.authUser?.phone;
             this.profile.phone_country_code_id =  this.authUser?.phone_country_code_id;
             this.profile.date_of_birth =  this.authUser?.date_of_birth;
             this.profile.gender =  this.authUser?.gender;
      }
   }
}
</script>