<template>
    <div class="single-product-wrap col-lg-3 col-md-6 col-sm-1 text-center">
        <!-- <p>{{ item.url }} </p> -->
        <div class="ma_2 snipcss-RedWI">
            <router-link :to="{ name: 'itemView', params: { itemId: item?.url[0]?.url ?? item.id }}" class="tile_img">
                <div class="im_wr">
                    <img
                        class="img_a ls-is-cached lazyloaded"
                        :src="item.certificate_image ? 'https://erp44.com/public/warehouse/certificate-images/'+item.certificate_image.filename:'http://placehold.jp/150x150.png'"
                        alt="GemLab Product"
                    />
                </div>
                <div class="d_wrap2">
                    <div class="p_title3">
                        <h2 class="text-center">
                            {{item.product.name ?? 'NO'}}
                            <!-- -{{item.product_grade.alias ?? 'NO'}} -->
                            <!-- -
                  {{item.ratti.rati_standard ?? 'NO'}} -->
                            <!-- - -->
                            ({{ item.weight }}MG)<br />
                            <span class="new-price text-center">SKU: {{ domainProfile.id }}{{ item.gin ?? '' }}</span> <br />

                            <span class="new-price text-center">
                                {{ currencySign }}
                            </span>
                            <span class="new-price" style="font-size: 16px; font-weight: 400;">{{ convertPrice(item.amounts.mrpAmount)}}</span>
                        </h2>
                    </div>

                    <div class="price-box mt-2"></div>
                </div>
            </router-link>
            <div class="but-container_pcs">
                <div id="add_to_cart_438417" class="buy_now_but_4 style-TwP6Y">
                    <div class="b_arr">
                        <div class="arr_right"></div>
                    </div>

                    <span v-if="isItemInCart(item.id) ">Added</span>
                    <span v-else @click="addToCart(item.id)">Add To Cart</span>
                </div>
                <!-- <div id="remove_from_cart_438417" class="rem_cart_1 style-JCWAd" onclick="rem_Prod('438417',event);">
      <div class="b_arr">
        <div class="arr_left">
        </div>
      </div>
      <span>
        Remove from Cart
      </span>
    </div> -->
                <!-- <div class="un_thumb style-k9ULl" id="unavailable_438417">
      Unavailable
    </div> -->
            </div>
            <div class="bn_w style-NFq1I" id="style-NFq1I">
                <div class="buy_now_but_3" id="buynow_438417">
                    <div class="b_arr">
                        <div class="arr_right"></div>
                    </div>
                    <!-- <router-link :to="{name : 'Checkout',query : {itemIds : `[${item.id}]`}}" class="d-inline ml-3" ><button name="buynw" class="btn-clas btn btn-primary btn-lg border-0"><i class="icon-wallet"></i> Buy Now</button></router-link> -->
                    <span>
                        <router-link :to="{name : 'Checkout',query : {itemIds : `[${item.id}]`}}" class=" ">
                            <!-- <i class="icon-wallet"></i>  -->
                            Buy Now
                        </router-link>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="single-product-wrap">
         <div class="product-image">
            <router-link :to="{ name: 'itemView', params: { itemId: item.id }}">
               <img :src="item.certificate_image ? 'https://erp44.com/public/warehouse/certificate-images/'+item.certificate_image.filename:'http://placehold.jp/150x150.png'" alt="GemLab Product">
            </router-link>
         </div>
         <div class="product-content">
            <h3>
               <router-link :to="{ name: 'itemView', params: { itemId: item.id }}">
                  {{item.product.name ?? 'NO'}}-{{item.product_grade.alias ?? 'NO'}}-{{item.ratti.rati_standard ?? 'NO'}}-({{ item.weight }}MG)<br/>
                  <span class="new-price" >SKU: {{ domainProfile.id }}{{ item.gin ?? '' }}</span>
               </router-link>
            </h3>
            <div class="price-box">
               <span class="new-price">Rs.{{ item.amounts.mrpAmount ?? '' }}</span>
            </div>
            <div class="price-box"> 
                    <span v-if="isItemInCart(item.id) "><button type="submit" name="add" class="btn-class2 btn btn-success btn-sm"><i class="ion-android-cart"></i> Added</button></span>
                     <span v-else><button type="submit" name="add" class="btn-class2 btn btn-success btn-sm" @click="addToCart(item.id)"><i class="ion-android-cart" ></i> Add To Cart</button></span>
                     <span><router-link :to="{name : 'Checkout',query : {itemIds : `[${item.id}]`}}" class="btn-class2 btn btn-success btn-sm "><i class="icon-wallet"></i> Buy Now</router-link></span> 
            </div>

         </div>
      </div>   -->
    <!-- </div> -->
</template>

<script>   
import {mapGetters,mapActions} from 'vuex'
   export default {
       name : 'Item',
       props :['item'],  
       computed:{
          ...mapGetters({
               domainProfile : 'domain/profile', 
           carts : 'cart/getAll',   
          })
       },
       mounted(){ 
            this.$store.dispatch('frontend/convertPrice', this.item.amounts.mrpAmount ?? '')
                    .then(result => {
                        this.convertedPrice = result;
                    })
                    .catch(error => {
                        console.error(error);
                    });
       },
       methods : {
        ...mapActions({
            fetchItem : 'frontend/fetchItem',  
            fetchAll : 'cart/all',
        }),  
        fetchConvertedPrice(){
          
        },
         addToCart(itemId){
            if(localStorage.getItem('authToken')){
               
            // this.inCart = true; 
            this.$store.dispatch('cart/save',itemId)
                  .then((data) => { 
                        if(data.errors){
                           this.errors = data.errors;
                        }else{
                           this.inCart = true;
                           this.fetchAll();
                           this.$router.push({name : 'Cart'});
                        }
                  });
         }else{
            localStorage.setItem('itemForCart',itemId);
            this.$router.push({name: 'Login'});
         }
         },
      isItemInCart(itemId){
         var exists = this.carts.some(function(cart) {
             return cart.item.id == itemId;
         });
         return exists ? true : false; 
      }, 
       }
   }
   
</script>
<style scoped>
  .ma_2:hover, .ma_22:hover {
	-webkit-box-shadow: 0px 2px 15px -2px rgba(179,173,179,1);
	-moz-box-shadow: 0px 2px 15px -2px rgba(179,173,179,1);
	box-shadow: 0px 2px 15px -2px rgba(179,173,179,1);
}
   .buy_now_but_4 { 
    z-index: 12; 
    cursor: pointer; 
    position: relative; 
    width: calc(100% - 3px); 
    height: 26px; 
    border: 1px; 
    border-style: solid; 
    border-radius: 16px; 
    border-color: rgb(179 173 179); 
    margin-left: auto; 
    margin-right: auto;
} 

.but-container_pcs { 
    z-index:  11; 
    /* position:  absolute;  */
    cursor:  pointer; 
    width:  100%; 
    height:  40px; 
    /* bottom:  30px;  */
    text-align:  center;

} 

.ma_2 { 
    /* float: left;  */
    /* width: 21%;  */
    display: inline-block; 
    white-space: normal; 
    vertical-align: top; 
    position: relative; 
    cursor: pointer; 
    margin: 20px 0 0 15px; 
    /* min-height: 480px;  */
    border: 10px solid white;
} 

#pr_cont { 
    display: inline-block; 
    width: calc(100% + 20px); 
    color: #4A4A4A; 
    margin: -5px 0 30px -7px;
} 

.content-new { 
    width: 100%;
} 

#prod_wra > #cent_wra > #content { 
    float: right; 
    width: calc(100% - 299px); 
    margin: 0 -3px 0 0;
} 

#cent_wra { 
    width: 100%; 
    margin-right: auto; 
    margin-left: auto; 
    display: inline-block; 
    position: relative;
} 

#prod_wra { 
    width:  calc(100% - 40px); 
    max-width:  1800px; 
    margin-right:  auto; 
    margin-left:  auto; 
    padding:  0 20px 0 20px; 
    min-width:  1000px;
    margin-top: 15px;
} 

body { 
    margin: 0px; 
    color: #555555; 
    font-family: Verdana, Arial, Helvetica, sans-serif; 
    font-size: 13px; 
    line-height: 18px; 
    text-align: left; 
    vertical-align: top; 
    min-height: 100%;
} 

.body_1 { 
    margin: 0px; 
    color: #555555; 
    font-family: Verdana, Arial, Helvetica, sans-serif; 
    font-size: 13px; 
    line-height: 18px; 
    text-align: left; 
    vertical-align: top; 
    min-height: 100%;
} 

html { 
    width: 100%; 
    min-height: 100%;
} 

.buy_now_but_4 span  { 
    font-size: 14px; 
    letter-spacing: 1px; 
    color: black; 
    line-height: 26px; 
    text-align: center; 
    display: block; 
    padding-right: 25px;
} 

.tile_img { 
    display: inline-block; 
    padding-bottom: 7px;
} 

a:link { 
    color: #000095; 
    text-decoration: none;
} 

a  { 
    color: black;
} 

a:hover { 
    color:  #C0571D; 
    text-decoration:  none;
} 

a:hover  { 
    color: black;
} 

.bn_w { 
    cursor: pointer; 
    /* position: absolute;  */
    /* bottom: 650;  */
    /* z-index: 11;  */
    height: 32px; 
    width: 100%;
} 

.im_wr { 
    display: inline-block; 
    position: relative;
    min-height: 193px;
    max-height: 193px;
} 

.d_wrap2 { 
    top: 265px; 
    display: block; 
    width: 100%; 
    left: 0px;
} 

.buy_now_but_4:hover { 
    background: linear-gradient(to bottom,#F4D284,#EDB521); 
    background-color: black;
} 

.rem_cart_1 { 
    z-index:  12; 
    cursor:  pointer; 
    position:  relative; 
    width:  calc(100% - 3px); 
    height:  26px; 
    border:  1px; 
    border-style:  solid; 
    border-radius:  16px; 
    border-color:  rgb(179 173 179); 
    margin-left:  auto; 
    margin-right:  auto;
    background-color: #ECFFEC;
} 

.rem_cart_1:hover { 
    background-color: #CEFFCE; 
    border-color: black;
} 

.buy_now_but_3 { 
    z-index: 12; 
    cursor: pointer; 
    position: relative; 
    width: calc(100% - 3px); 
    height: 26px; 
    border: 1px; 
    border-style: solid; 
    border-radius: 16px; 
    border-color: rgb(179 173 179); 
    margin-left: auto; 
    margin-right: auto;
} 

.buy_now_but_3:hover { 
    background: linear-gradient(to bottom,#F3B669,#D97E11); 
    background-color: black;
} 

img { 
    border: 0px;
} 

.img_a {  
    max-height: 193px;
    max-width: 193px;
} 

.v_ico { 
    z-index: 11; 
    position: absolute; 
    height: 40px; 
    width: 40px; 
    bottom: 10px; 
    right: 10px;
} 

.p_title3 { 
    /* margin: 7px 0 28px 0; */
} 

.p_price { 
    text-decoration:  none; 
    padding:  3px 0 3px 0; 
    float:  left; 
    width:  50%; 
    vertical-align: top;
} 

.p_size { 
    text-decoration:  none; 
    padding:  3px 0 3px 0; 
    float:  left; 
    width:  50%;
    text-align: center; 
    top: -.3em; 
    position: relative;
} 

.p_stock { 
    display: none;
    text-decoration:  none; 
    padding:  3px 0 3px 0; 
    float:  left; 
    width:  50%;
} 

.buy_now_but_4 .b_arr  { 
    background-image:   url(https://www.gemselect.com/media/button/sprite_9.png); 
    background-repeat:   no-repeat; 
    background-repeat-x:   no-repeat; 
    background-repeat-y:   no-repeat;
    position:  absolute; 
    right:  -1px; 
    top:  -1px; 
    height:  28px; 
    width:  28px; 
    border-radius:  50%; 
    background-color:  #595959;
    background-position: -246px 7px;
} 

.buy_now_but_4 .b_arr ,.buy_now_but_4 .b_arr:hover  { 
    background-image: url(https://www.gemselect.com/media/button/sprite_9.png); 
    background-repeat: no-repeat; 
    background-repeat-x: no-repeat; 
    background-repeat-y: no-repeat;
} 

.buy_now_but_4:hover .b_arr  { 
    background-color: black;
} 

.rem_cart_1 .b_arr  { 
    position: absolute; 
    right: -1px; 
    top: -1px; 
    height: 28px; 
    width: 28px; 
    border-radius: 50%; 
    background-color: #595959;
} 

.rem_cart_1:hover .b_arr  { 
    background-color: black;
} 

.rem_cart_1 span  { 
    font-size: 14px; 
    letter-spacing: 1px; 
    color: black; 
    line-height: 26px; 
    text-align: center; 
    display: block; 
    padding-right: 25px;
} 

.buy_now_but_3 .b_arr  { 
    position: absolute; 
    right: -1px; 
    top: -1px; 
    height: 28px; 
    width: 28px; 
    border-radius: 50%; 
    background-color: #595959;
} 

.buy_now_but_3:hover .b_arr  { 
    background-color: black;
} 

.buy_now_but_3 span  { 
    font-size: 14px; 
    letter-spacing: 1px; 
    color: black; 
    line-height: 26px; 
    text-align: center; 
    display: block; 
    padding-right: 25px;
} 

h2 { 
    font-size: 14px; 
    color: #6C0000; 
    padding-top: 0px; 
    font-weight: bold; 
    margin-bottom: -3px; 
    margin-top: 0px;
} 

.p_title3 h2  { 
    display:  -webkit-box; 
    font-weight:  normal; 
    text-align:  left; 
    font-size:  14px; 
    line-height:  22px; 
    color:  #2E2E2E; 
    padding:  0; 
    -webkit-line-clamp:  4; 
    max-height:  90px; 
    overflow:  hidden; 
    text-overflow:  ellipsis; 
    -webkit-box-orient:  vertical;
    /* min-height: 874px; */
} 

.curr_selected_2 { 
    font-size: 12px; 
    top: -.6em; 
    position: relative; 
    color: #0F1111; 
    font-weight: 400;
} 

.p_price span  { 
    line-height: 15px;
} 

.rem_cart_1 .b_arr .arr_left  { 
    width: 0px; 
    height: 0px; 
    border-top: 7px solid transparent; 
    border-bottom: 7px solid transparent; 
    border-right: 9px solid white; 
    margin-top: 7px; 
    margin-left: 8px;
} 

.buy_now_but_3 .b_arr .arr_right  { 
    width: 0px; 
    height: 0px; 
    border-top: 7px solid transparent; 
    border-bottom: 7px solid transparent; 
    border-left: 9px solid white; 
    margin-top: 7px; 
    margin-left: 11px;
} 

.whole_pr2 { 
    font-size: 21px !important; 
    font-weight: 400 !important;
} 

.fraction_pr { 
    font-size: 12px; 
    top: -.6em; 
    position: relative; 
    color: #0F1111; 
    font-weight: 400;
} 


/* These were inline style tags. Uses id+class to override almost everything */
#style-mUNro.style-mUNro {  
   display:none;  
}  
#add_to_cart_438417.style-TwP6Y {  
   display:;  
}  
#remove_from_cart_438417.style-JCWAd {  
   display:none;  
}  
#unavailable_438417.style-k9ULl {  
   display:none;  
}  
#style-NFq1I.style-NFq1I {  
   display:;  
}  

</style>