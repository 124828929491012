import {HTTP} from '../http-common' 
export default {
    namespaced:true,
    state : {
        all :[],
        totalAmount : 0, 
    },
    getters : { 
        getAll : (state) => {
            return state.all
        },
        cartTotal : (state) => {
            return state.all.length
        },
     },
    
     mutations : { 
        setAll:(state,data) => {
            state.all = data;
        }, 
    },
    
    actions : { 
        async all(context) { 
            
            if(localStorage.getItem('authToken')){
                return await HTTP.post(`cart-all`).then(response => {
                    context.commit('setAll', response.data); 
                }); 
            }
        },
        async save({dispatch},itemId) { 
               return await HTTP.post('cart-save', {
                    itemId : itemId
                })
                .then(({data}) => { 
                    dispatch('all');
                    return data;
                });   
        },
        async delete({dispatch}, cartId) {
            return await HTTP.post(`cart-delete`,{
                    cartId : cartId
                }).then(res => {
                    dispatch('all');
                });
        }, 
    },
    modules : {}
} 