<template>
   <div class="main-content-wrap section-ptb cart-page pb-0">
      <div class="container">
         <h2>My Cart</h2>
         <div class="row" v-if="all.length">
            <div class="col-12 mb-5 pb-5">
               <form method="post" enctype="multipart/form-data" class="cart-table">
                  <div class="table-content table-responsive">
                     <table class="table mb-2">
                        <thead>
                           <tr>
                              <th class="plantmore-product-thumbnail">Image</th>
                              <th class="cart-product-name">SKU</th>
                              <th class="cart-product-name">Product</th>
                              <th class="plantmore-product-price">Ratti</th>
                              <th class="plantmore-product-subtotal">Amount</th>
                              <th class="plantmore-product-remove">Remove</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr style="display:none;">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                           </tr>
                           <template v-if="all.length">
                           <tr v-for="cart in all" :key="cart.id">
                              <td class="plantmore-product-thumbnail">
                                 <a v-if="cart?.item"> 
                                 <img style="max-width:100px;" :src="cart.item.certificate_image ? 'https://erp44.com/public/warehouse/certificate-images/'+cart.item.certificate_image.filename:'http://placehold.jp/150x150.png'"   >
                                 </a>
                                 </td>
                              <td class="plantmore-product-name"><a>{{ domainProfile.id }}{{cart.item.gin}}</a></td>
                              <td class="plantmore-product-name"><a>{{cart.item.product.alias ?? ''}}</a></td>
                              <td class="plantmore-product-price"><span class="amount">
                                 {{cart.item.ratti.rati_standard  ?? ''}}+</span>
                              </td>
                              <td class="product-subtotal"><span class="amount">
                                 
                                      {{ currencySign }}{{convertPrice(cart.item.amounts.mrpAmount)}}
                               </span>
                              </td>
                              <td class="plantmore-product-remove"><a href="javascript:void(0)" @click="removeCartItem(cart.id)" class="text-muted"><i class="ion-close"> </i> </a> </td>
                           </tr>
                           </template>
                        </tbody>
                     </table>
                  </div>
                  <!-- <div class="table-content table-responsive" v-if="!isDisable">
                     <div class="coupon2">
                 <h3>Your Cart Is Empty!</h3>
      <router-link  class="" data-tip="Quick View" :to="{ name: 'Homepage'}"> 
      <span>Continue Shopping at GemLab</span>
      </router-link>  
                     </div>
                  </div> -->
                  <div class="row">
                     <div class="col-md-8">
                     </div>
                     <div class="col-md-4 ml-auto  ">
                        <div class="card" > 
                           <div class="card-header">
                              Total({{ cartTotal }} items) :
                              
                           {{ currencySign }}{{convertPrice(totalAmount ? totalAmount : 0 )}}  
                               </div>
                           <div class=""> 
                               <button type="button" class="btn btn-primary float-right"  @click="checkout()">Proceed to checkout</button> 
                               </div> 
                        </div> 
                     </div>
                  </div>
               </form>
            </div>
         </div>
         <div class="text-center" v-else>
            <h1>Empty Cart</h1>
         </div>
      </div>
   </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'  
export default {
    name:'Cart',  
         computed : {
           ...mapGetters({
               all : 'cart/getAll',
               cartTotal : 'cart/cartTotal',
               domainProfile : 'domain/profile',

               // totalAmount : 'cart/totalAmount', 
            }),  
            totalAmount(){
               let sum = 0;
               this.all.forEach(({item}) => {
                    sum += parseFloat(item.amounts.mrpAmount);
               });
               return sum;
            },
            itemIds(){
            let itemIds = [];
             this.all.forEach(({item}) => { 
                        itemIds.push(item.id);            
                     });
                  return JSON.stringify(itemIds);
            }
    },
    methods:{
       checkout(){
           this.$router.push({
               name: 'Checkout',
               query : {
                 itemIds : this.itemIds
               },
            });
       },
        ...mapActions({
            fetchAll : 'cart/all',
            save : 'cart/save',
            delete : 'cart/delete',
        }),
        removeCartItem(cartId){
              this.$store.dispatch('cart/delete',cartId);  
        },  


    },
   mounted(){
         this.fetchAll();
   },
    
}
</script>

<style scoped>
.timeline {
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 500px; 
  counter-reset: step;
}

.timeline li {
  float: left;    
  text-align: center;
  width: 140px;   
  position: relative;  
}

.timeline li:before {
  content: counter(step);
  counter-increment: step;
  line-height: 35px;
  width: 35px;
  height: 35px;
  display: block;
  border-radius: 100%;
  border: 1px solid #CCC;
  margin: 0 auto 15px auto;  
  background: #fff;
}
.timeline li:after {
  content: '';
  width: 100%;
  height: 1px;
  background: #DDD;
  display: block;
  position: absolute;
  top: 17.5px;
  left: 50%;
  z-index: -1;
}

.timeline li:last-child:after {
  content: none;
}
.timeline li.active {
  color: #8cc413;
}
.timeline li:first-child:before {
  border-color: #8cc413;  
}
.timeline li:first-child:after {
  background: #8cc413;  
}
</style>