import {
    HTTP
} from '../http-common'
export default {
    namespaced: true,
    state: {
        all: [], 
        order: {
            payment : {
                id : 0
            }
        }, 
    },
    getters: {
        getAll(state) {
            return state.all;
        }, 
        getOrder(state) {
            return state.order;
        }, 
    },
    mutations: {
        setAll(state, data) {
            state.all = data;
        }, 
        setOrder(state, data) {
            state.order = data;
        }, 
    },
    actions: {
        async fetchAll(context) { 
                await HTTP.post(`orders`).then(({data}) => {
                    context.commit('setAll', data);
                }); 
        }, 
        async fetchOrder(context,orderId) { 
                await HTTP.post(`order`,{
                    orderId : orderId
                }).then(({data}) => {
                    context.commit('setOrder', data);
                }); 
        }, 
    }
}