<template>
    <div class="container py-5">
      <!-- <p> {{ page }} </p> -->

   <div v-html="page.body"></div>
    </div>
</template>
<script> 

import {HTTP} from '../../../http-common'
// import BlogItem from './item.vue' 
export default {
    name : 'View', 
    data(){
         return{ 
             page:{}
        }
    },
    mounted(){
      this.getPageView();
    },
    components : {
    //    BlogItem, 
    },
    methods:{
         getPageView : function(){
             HTTP.get(`page/${this.$route.params.id}`).then(res => {  
                this.page = res.data.page
             });
         } 
    }
} 
</script>
 