<template>
       
      
      <div class="main-content-wrap section-ptb product-details-page">
         <div class="container"> 
            <div class="row">
                <div class="col-md-3 "> 
                    <div class="list-group mb-sm-2">
                    <a href="#" class="list-group-item list-group-item-action active">All Orders</a>
                    <!-- <a href="#" class="list-group-item list-group-item-action">Cancelled Orders</a>
                    <a href="#" class="list-group-item list-group-item-action">Not Yet Shipped</a>  -->
                 </div>
                </div>
               <div class="col-md-9">
                  <div class="row" v-if="all.length">
                     <div class="col-md-12"  v-for="order in all" :key="order.id">

                  <div class="a-box p-4 a-color-offset-background order-info" > 
                      <div class="row" style="font-size: 14px; line-height: 15px;" >
                          <div class="col-md-3 mb-sm-2">
                           <div>
                            ORDER PLACED
                           </div>
                           <div>
                              {{ order.placed_on }}
                           </div>
                          </div>  <div class="col-md-1 mb-sm-2">
                           <div class="">
                                Qty.

                            </div>
                            <div class="">
                                {{ order.total_qty }}
                            </div>
                          </div>
                          <div class="col-md-2 mb-sm-2">
                            <div>
                                TOTAL
                            </div>
                            <div>
                                Rs.{{ order.total_amount }}
                            </div>
                          </div>
                          <div class="col-md-2 mb-sm-2">
                            <div>
                                SHIP TO

                            </div>
                            <div>
                                {{ order?.shipping_address2?.name ?? "" }}
                            </div>
                          </div>
                        
                          <div class="col-md-4 mb-sm-2 text-md-right">
                            <div>
                                ORDER # {{ order.id }}

                            </div>
                            <div>
                               <router-link :to="{name : 'OrderView',params : {id : order.id ?? 1001}}">View order details </router-link>  
                            </div>
                          </div>
                      </div> 
                  </div>
                  <div class="a-box order-info mb-3">
                    <div class="a-box-inner">
                       <!-- Delivered NULL -->
                       <div class="row">
                         <div class="col-12" v-for="(detail, index) in order.details" :key="index">
                             <div class="row box-inner">
                                <div class="col-md-2"> 
                                       <a v-if="detail?.item"> 
                                 <img :src="detail.item.certificate_image ? detail.item.certificate_image.path :'http://placehold.jp/150x150.png'"   >
                                 </a>
                                </div>
                                <div class="col-md-10">
                                   <span class="a-color-secondary value" style="font-size: 14px;"> {{ detail.item.iname }}
                                   </span>
                                   <div class="a-row a-size-small" style="font-size: 14px; line-height: 18px;">SKU : {{ domainProfile.id }}{{ detail.item.gin }}</div>
                                </div>
                             </div> 
                           </div>   
                       </div>
                    </div>
                 </div>
                     </div>
                  </div>
                  <div class="row" v-else>
                     <h4>No Orders</h4>
                  </div>
                  
               </div> 
            </div>
         </div>
      </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex'
export default {
   name : 'All',
   computed : {
      ...mapGetters({
         'all' : 'order/getAll',
         'domainProfile' : 'domain/profile',
      })
   },
   methods : {
        ...mapActions({
            fetchAll : 'order/fetchAll', 
        })
   },
   mounted(){
        this.fetchAll(); 
   }
}
</script>