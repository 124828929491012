<template>
   <div>
      <Slider></Slider>
      <section class="" >
         <div class="container">
            <div class="row">
               <div class="col-12">
                  <div class="section-title">
                     <h3>
                           Natural & Certified Gemstones
                     </h3> 
                  </div>
               </div>
                <div class="container-fluid"> 
      <div class="row justify-content-center commonBottomMargin commontopMargin">
         <Product v-for="product in products" :key="product.id" :product='product'> </Product>
      </div>
   </div>
            </div>
         </div>
      </section>
      <div class="banner-area section-pb section-pt pb-md-2 pb-0" style="background: #f7f7f7;">
         <Banner></Banner>
      </div>
   </div>
</template>
<script>
   import {mapGetters} from 'vuex'
   import Slider from './Slider.vue' 
   import Banner from './Banner.vue'
   import Product from './Product.vue'

   export default {
       name:'Home', 
       components:{ Slider, Banner,Product }, 
      computed: { 
          ...mapGetters({ 
             products : 'frontend/getProducts', 
            domainProfile : 'domain/profile', 
         })
       }, 
  watch : {
    domainProfile(){
      if(this.domainProfile?.isFromIndia){ 
          localStorage.setItem('currency','INR');
          localStorage.setItem('currencySign','Rs.');
      }else{
          localStorage.setItem('currency','USD');
          localStorage.setItem('currencySign','$');
      }
    }
  }
   
   }
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap');  

body { 
    margin: 0px;  
    font-family: Verdana, Arial, Helvetica, sans-serif;  
    line-height: 18px;  
}    
@media (min-width: 576px) {
   h3  { 
      font-size:  3em; 
      color:  #6E6E6E; 
      font-weight:  400; 
      line-height:  1.4em; 
      margin:  20px 15px 20px 15px; 
      letter-spacing:  0.06em; 
      text-align:  center;
      text-shadow:  3px 3px 6px #c8c8c8;
   } 
} 

@media (max-width: 576px) {
   h3  { 
      font-size:  1.5em; 
      color:  #6E6E6E; 
      font-weight:  400; 
      line-height:  1.4em; 
      margin:  20px 15px 20px 15px; 
      letter-spacing:  0.06em; 
      text-align:  center;
      text-shadow:  3px 3px 6px #c8c8c8;
   } 
} 
</style>