import {HTTP} from '../http-common' 
export default {
    namespaced:true,
    state : {
        all :[],
        totalAmount : 0,
        addressId : 0,
        paymentMethods : 0,
        paymentMethodId : 0,
    },
    getters : { 
        getAll : (state) => {
            return state.all
        },
        getTotalAmount : (state) => {
            return state.totalAmount
        },
        getPaymentMethods : (state) => {
            return state.paymentMethods
        },
     },
    
     mutations : { 
        setAll:(state,data) => {
            state.all = data;
        }, 
        setTotalAmount:(state,data) => {
            state.totalAmount = data;
        }, 
        setPaymentMethods:(state,data) => {
            state.paymentMethods = data;
        },
    },
    
    actions : { 
        // async all(context) { 
        //     return await HTTP.post(`items-by-ids`).then(({data}) => {
        //         context.commit('setAll', data); 
        //     }); 
        // },
        async fetchPaymentMethods(context) { 
            return await HTTP.post(`payment-methods`).then(({data}) => {
                context.commit('setPaymentMethods', data); 
            }); 
        },
        // async placeOrder({dispatch,state}) { 
        //        return await HTTP.post('cart-save', {
        //             itemIds : itemIds,
        //             paymentMethodId : state.paymentMethodId,
        //             addressId : state.addressId,
        //         })
        //         .then(({data}) => { 
        //             return data;
        //             // dispatch('all');
        //             // return data;
        //         });   
        // }, 
    },
    modules : {}
} 