<template>
   <div class="container">
      <div class="col-md-9"> 
      </div>
   </div>
</template> 
<script> 

import {HTTP} from '../../../http-common'
import {mapGetters} from 'vuex'
export default { 
  data(){
    return{
      script: `https://checkout.razorpay.com/v1/checkout.js`
    }
  }, 
  mounted(){
   
  },
  methods :{ 
    async loadRazorPay(){
      return new Promise(resolve=>{
        const script = document.createElement('script')
        script.src = this.script
        script.onload = () =>{
          resolve(true)
        }
        script.onerror = () =>{
          resolve(false)
        }        
        document.body.appendChild(script)
      })      
    }
  },
  async created(){
     const query = this.$route.query; 
     window.location = `${HTTP.paymentUrl}/razorpay/pay.php?key=${query.key}&amount=${query.amount}&currency=${"INR"}&name=${"ERP44"}&email=${query.email}&contact=${query.contact}&order_id=${query.order_id}&store_order_id=${query.store_order_id}&success_url=${window.location.origin}/order/view/${query.store_order_id}&base_url=${HTTP.defaults.baseURL}&token=${query.token}`;
  },
  computed : {
     ...mapGetters({
        authUser : 'auth/authUser',
     }),
  }
} 
</script>
 