<template>
  <div class="main-content-wrap section-ptb product-details-page">
         <div class="container">
      <router-view></router-view>    
         </div>
  </div> 
</template>
<script>
import { mapGetters,mapActions } from 'vuex'
export default {
    name : 'AddressIndex',
    computed : {
        ...mapGetters({
            'addresses' : 'address/all'
        })
    },
    methods : {
        ...mapActions({
            getAll : 'address/all',
            getCountries:'address/getCountries',
            getStates:'address/getStates',
            getCities :'address/getCities', 
        })
    },
    mounted(){
        this.getAll();
        this.getCountries();
    }
    
}
</script>