<template>
  <div class="a-section a-spacing-base">
    <div class="a-box">
      <div class="a-box-inner a-padding-extra-large">
        <h1 class="a-spacing-small">
          Sign in
        </h1>
        <div class="a-row a-spacing-base">
          <span>{{ formattedPhone }}</span> 
          
          <router-link  :to="{ name:'Login'}"  id="ap_change_login_claim" class="a-link-normal ml-2" href="">Change</router-link>
        </div>
        <form  @submit="event.preventDefault()"  method="post" novalidate="" class="auth-validate-form auth-real-time-validation a-spacing-none" data-fwcim-id="h6qrgJYL">
          <div class="a-section">
            <div class="a-section a-spacing-large">
              <div class="a-row">
                <div class="a-column a-span5">
                  <label for="ap_password" class="a-form-label">
                  Password
                  </label>
                </div>
                <div class="a-column a-span7 a-text-right a-span-last">
                  <router-link :to="{name : 'ForgotPassword'}"  id="auth-fpp-link-bottom" class="a-link-normal float-right" >
                    Forgot Password
                  </router-link>
                </div>
              </div>
              <input autocomplete="off" ref="focus" type="password" maxlength="1024" id="ap_password"  v-on:keyup.enter="submitPassword()"  v-model="password" class="a-input-text a-span12 auth-autofocus auth-required-field">
              <span class="text-danger">{{errors.password ?? ""}}</span>
            </div>
            <div class="a-section">
              <span id="auth-signin-button" class="a-button a-button-span12 a-button-primary auth-disable-button-on-submit"><span class="a-button-inner">
              <input autocomplete="off" id="signInSubmit" class="a-button-input" @click="submitPassword()" aria-labelledby="auth-signin-button-announce">
              <span id="auth-signin-button-announce" class="a-button-text" aria-hidden="true">
              Sign in
              </span></span></span>
            </div>
          </div>
        </form>
      </div>
    </div> 
    <div class="a-section">
      <div class="a-divider a-divider-break">
        <h5>or</h5>
      </div>
    </div>
    <span id="auth-login-via-otp-btn" class="a-button a-button-span12 a-button-base"><span class="a-button-inner">
    <input autocomplete="off" id="continue" class="a-button-input" @click="loginWithOtp()" type="submit" aria-labelledby="auth-login-via-otp-btn-announce">
    <span id="auth-login-via-otp-btn-announce" class="a-button-text" aria-hidden="true">
    Get an OTP on {{ formattedPhone }}
    </span></span></span> 
  </div>
</template>
<script> 
import {HTTP} from '../../../http-common'
export default {
   name: 'LoginPassword',
   data() {
      return {
         phone : '',
         userId : '',
         password : '', 
         isPhone : Number(this.$route.query.isPhone), 
         errors: { 
            password : ''
         },
      }
   },
    mounted(){ 
      this.$refs.focus.focus();
    }, 
    computed : {
       formattedPhone(){
           if(Number(this.$route.query.isPhone)){
               return `${this.$route.query.phone}`;
           }
           return this.$route.query.phone;
       }
    },
   methods: {
      submitPassword(){
         this.errors = {},
         HTTP.post(`login-password-submit`,{
            userId : this.$route.query.userId,
            password : this.password,
            phone : this.$route.query.phone, 
            isPhone : this.$route.query.isPhone, 
         }).then(({data}) => {
            if(!data.errors){
              if(data.userId){
                  this.$router.push({
                     name: 'RegisterOtp',
                     query: {
                        userId : data.userId, 
                        regIdPhone : data.regIdPhone ?? false, 
                        regIdEmail : data.regIdEmail ?? false, 
                        isPhone : data.isPhone, 
                        phone : data.phone, 
                        email : data.email, 
                     }
                  });
              }else{
                this.$store.dispatch('auth/login',data) 
                this.$root.refresh = true;
                localStorage.setItem('oneTimeRefresh',true);
                let redirect = ''
                if(localStorage.getItem('redirect')){
                   redirect = localStorage.getItem('redirect')
                   localStorage.removeItem('redirect')
                   window.location = redirect;
                }else{
                  redirect = this.$router.push({name : 'Dashboard',param : {reload : true}})
                }  
              }
            }else{
            this.errors = data.errors;
            }
         })
      }, 
      loginWithOtp(){
         this.errors = {},
         HTTP.post(`login-send-otp`,{
            userId : this.$route.query.userId,
            phone : this.$route.query.phone, 
            isPhone : this.$route.query.isPhone, 
         }).then(({data}) => { 
            if(!data.errors){
               this.$router.push({
                 name: 'LoginOtp',
                 query: {
                   userId : data.userId, 
                   regId : data.regId, 
                   phone : data.phone, 
                   isPhone : this.$route.query.isPhone, 
               }
            });
            }else{
               this.errors = data.errors;
            }
         })
      },   
   }
}
</script>
