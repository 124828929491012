<template>
    <div class="a-section a-spacing-base">
      <div class="a-box">
        <div class="a-box-inner a-padding-extra-large">
          <h1>
            <template v-if="isPhone">
            Verify mobile number
            </template>
            <template v-else>
            Verify email
            </template>
          </h1>
          <div class="a-row a-spacing-none">
            <p>
              
            <template v-if="isPhone">
            
              A text with a One Time Password (OTP) has been sent to your mobile number: 
               <span class="a-text-bold">
              {{ formattedPhone }}
              </span>
            </template>
            <template v-else>
            An email with a One Time Password (OTP) has been sent to your email address: 
               <span class="a-text-bold">
              {{ formattedPhone }}
              </span>
            </template> 
            </p>
          </div>
          <form  @submit="event.preventDefault()"  method="post" novalidate="" class="auth-validate-form auth-real-time-validation a-spacing-none" data-fwcim-id="h6qrgJYL">
            <div class="a-section mt-3">
              
            <template v-if="isPhone"> 
           <div class="a-section a-spacing-large">
                <div class="a-row">
                  <div class="a-column a-span5"><label for="ap_password" class="a-form-label">
                    Enter Phone OTP:
                    </label>
                  </div>
                  <div class="a-column a-span7 a-text-right a-span-last">
                    <router-link :to="{name : 'ForgotPassword'}"  id="auth-fpp-link-bottom" class="a-link-normal float-right" >
                      Resend OTP
                    </router-link>
                  </div>
                </div>
                <input autocomplete="off" ref="focus" type="password" maxlength="1024"  v-on:keyup.enter="registerOtpVerify()" id="ap_password" v-model="phoneOtp" class="a-input-text a-span12 auth-autofocus auth-required-field">
                <div id="auth-customerName-missing-alert" v-if="errors.phoneOtp" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
                  <div class="a-box-inner a-alert-container">
                    <i class="a-icon a-icon-alert"></i>
                    <div class="a-alert-content">
                      {{errors.phoneOtp}}
                    </div>
                  </div>
                </div>
              </div> 
            </template>
            <template v-else>   <div class="a-section a-spacing-large">
                <div class="a-row">
                  <div class="a-column a-span5"><label for="ap_password" class="a-form-label">
                    Enter Email OTP:
                    </label>
                  </div>
                  <!-- <div class="a-column a-span7 a-text-right a-span-last">
                    <router-link :to="{name : 'ForgotPassword'}"  id="auth-fpp-link-bottom" class="a-link-normal float-right" >
                      Resend OTP
                    </router-link>
                  </div> -->
                </div>
                <input autocomplete="off" ref="focus" type="password" maxlength="1024" id="ap_password"  v-on:keyup.enter="registerOtpVerify()" v-model="emailOtp" class="a-input-text a-span12 auth-autofocus auth-required-field">
                <div id="auth-customerName-missing-alert" v-if="errors.emailOtp" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
                  <div class="a-box-inner a-alert-container">
                    <i class="a-icon a-icon-alert"></i>
                    <div class="a-alert-content">
                      {{errors.emailOtp}}
                    </div>
                  </div>
                </div>
              </div>
            </template>
              <div class="a-section">
                <span id="auth-signin-button" class="a-button a-button-span12 a-button-primary auth-disable-button-on-submit"><span class="a-button-inner">
                <input autocomplete="off" id="signInSubmit" class="a-button-input" @click.prevent="registerOtpVerify()" aria-labelledby="auth-signin-button-announce">
                <span id="auth-signin-button-announce" class="a-button-text" aria-hidden="true">
                Submit
                </span></span></span>
              </div> 
              <div class="a-section">
                <div class="a-section a-spacing-none a-spacing-top-large a-text-center cvf-widget-section-js">
                  <a type="button" id="cvf-resend-link" class="a-link-normal cvf-widget-btn cvf-widget-link-resend cvf-widget-link-disable-target" @click="resendOtp()">
                    <i class="icon-reload"></i>
                    Resend OTP
                  </a>
                </div>
              </div>
              <div class="a-section" v-if="isPhone">
                <div class="a-section a-spacing-none a-spacing-top-large a-text-center cvf-widget-section-js">
                  <a type="button" id="cvf-resend-link" class="a-link-normal cvf-widget-btn cvf-widget-link-resend cvf-widget-link-disable-target" @click="sendVoiceOtp()">
                    <i class="icon-call-in"> </i>
                   Voice OTP
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>
<script> 
import {HTTP} from '../../../http-common'
import {mapGetters} from 'vuex'
export default { 
    name:'registerVerification',
    data(){
        return {
            phoneOtp : '',
            emailOtp : '',
            errors: {
               phoneOtp : '',
               emailOtp : '',
            },
        }
    }, 
    mounted(){
      this.$refs.focus.focus();
    },
   computed:{
          ...mapGetters({  
            domainProfile : 'domain/profile', 
    }),
    
    formattedPhone(){
        if(Number(this.$route.query.isPhone)){
            return `${this.$route.query.phone}`;
        }
        return this.$route.query.email;
    },
    
       isPhone(){
           if(Number(this.$route.query.isPhone)){
               return 1;
           }
           return 0;
       },
     },
    methods:{
           async registerOtpVerify(){
              this.errors = {},
              HTTP.post(`register-otp-verify`,{
                 userId : this.$route.query.userId,
                 regIdEmail : this.$route.query.regIdEmail,
                 regIdPhone : this.$route.query.regIdPhone,
                 phoneOtp : this.phoneOtp,
                 emailOtp : this.emailOtp,
                 isPhone : this.$route.query.isPhone,
              }).then(({data}) => {
                 if(!data.errors){ 
                 
                this.$store.dispatch('auth/login',data) 
                this.$root.refresh = true;
                localStorage.setItem('oneTimeRefresh',true);
                let redirect = ''
                if(localStorage.getItem('redirect')){
                   redirect = localStorage.getItem('redirect')
                   localStorage.removeItem('redirect')
                   window.location = redirect;
                }else{
                  redirect = this.$router.push({name : 'Dashboard',param : {reload : true}})
                }  
                 }else{
                    this.errors = data.errors;
                 }
              })
        }, 
           async sendVoiceOtp(){ 
              HTTP.post(`register-voice-otp`,{
                 userId : this.$route.query.userId, 
                 regId : this.$route.query.regIdPhone, 
              }).then(({data}) => {
                //  if(!data.success){
                //     alert('failed');
                //   }else{ 
                //      alert('Success');
                //  }
              })
        }, 
           async resendOtp(){ 
              HTTP.post(`register-resend-otp`,{
                 userId : this.$route.query.userId, 
                 regId : this.isPhone ?  this.$route.query.regIdPhone : this.$route.query.regIdEmail, 
              }).then(({data}) => {
                //  if(!data.success){
                //     alert('failed');
                //   }else{ 
                //      alert('Success');
                //  }
              })
        }, 
    }, 

}
</script>