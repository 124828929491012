<template> 
    <div class="a-section a-spacing-base">
      <div class="a-box">
        <div class="a-box-inner a-padding-extra-large">
          <h1>Authentication required</h1>
          <div class="a-row a-spacing-none">
            <p><strong>{{ phone }} </strong>
              <a class="a-link-normal ml-2" href="https://www.amazon.in/ap/signin?arb=1ec851e1-5c7a-4ad1-ba03-6c1e6227c7c5">Change        </a>
            </p>
            <p>We have sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification</p>
          </div>
          <form  @submit="event.preventDefault()"  method="post" novalidate="" class="auth-validate-form auth-real-time-validation a-spacing-none" data-fwcim-id="h6qrgJYL">
            <div class="a-section">
              <div class="a-section a-spacing-large mt-2">
                <div class="a-row">
                  <div class="a-column a-span5">
                    <label for="ap_password" class="a-form-label">
                    Enter OTP
                    </label>
                  </div>
                </div>
                <input autocomplete="off" ref="focus" type="password" maxlength="1024" id="ap_password" v-model="otp"   v-on:keyup.enter="loginOtpVerify()" class="a-input-text a-span12 auth-autofocus auth-required-field">
              </div>
              <div class="a-section">
                <span id="auth-signin-button" class="a-button a-button-span12 a-button-primary auth-disable-button-on-submit"><span class="a-button-inner">
                <input autocomplete="off" id="signInSubmit" class="a-button-input" @click="loginOtpVerify()" aria-labelledby="auth-signin-button-announce">
                <span id="auth-signin-button-announce" class="a-button-text" aria-hidden="true">
                Sign in
                </span></span></span>
              </div>
              <div class="a-section">
                <div class="a-section a-spacing-none a-spacing-top-large a-text-center cvf-widget-section-js">
                  <a type="button" id="cvf-resend-link" class="a-link-normal cvf-widget-btn cvf-widget-link-resend cvf-widget-link-disable-target" @click="resendOtp()">
                    <i class="icon-reload"></i>
                    Resend OTP
                  </a>
                </div>
              </div>
              <div class="a-section" v-if="isPhone">
                <div class="a-section a-spacing-none a-spacing-top-large a-text-center cvf-widget-section-js">
                 
                  <a type="button" id="cvf-resend-link" class="a-link-normal cvf-widget-btn cvf-widget-link-resend cvf-widget-link-disable-target" @click="sendVoiceOtp()">
                    <i class="icon-call-in"> </i>
                   Voice OTP
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <template v-if="isPhone"> -->
    <div class="a-section">
      <div class="a-divider a-divider-break">
        <h5>or</h5>
      </div>
    </div>
    <span id="auth-login-via-otp-btn" class="a-button a-button-span12 a-button-base"><span class="a-button-inner">
    <input autocomplete="off" id="continue" class="a-button-input" @click="loginWithPassword()" type="submit" aria-labelledby="auth-login-via-otp-btn-announce">
    <span id="auth-login-via-otp-btn-announce" class="a-button-text" aria-hidden="true">
    Sign in with your password
    </span></span></span>  
</template>
<script>
import {HTTP} from '../../../http-common'
export default {
    name:'OtpVerify',
      data (){
        return{ 
            otp : '', 
            phone : this.$route.query.phone, 
            errors: {
               otp : ''
            },
        }
    }, 
    mounted(){
      this.$refs.focus.focus();
    },
    computed : {
      
       formattedPhone(){
           if(Number(this.$route.query.isPhone)){
               return `${this.$route.query.phone}`;
           }
           return this.$route.query.phone;
       },
       isPhone(){
           if(Number(this.$route.query.isPhone)){
               return 1;
           }
           return 0;
       },
    },
    methods:{ 
           async loginOtpVerify(){
              this.errors = {},
              HTTP.post(`login-otp-verify`,{
                 userId : this.$route.query.userId,
                 regId : this.$route.query.regId,
                 otp : this.otp,
                 isPhone : this.$route.query.isPhone,
                 phone : this.$route.query.phone,
              }).then(({data}) => {
                 if(!data.errors){
                  
                this.$store.dispatch('auth/login',data) 
                this.$root.refresh = true;
                localStorage.setItem('oneTimeRefresh',true);
                let redirect = ''
                if(localStorage.getItem('redirect')){
                   redirect = localStorage.getItem('redirect')
                   localStorage.removeItem('redirect')
                   window.location = redirect;
                }else{
                  redirect = this.$router.push({name : 'Dashboard',param : {reload : true}})
                }  
                 }else{
                    this.errors = data.errors;
                 }
              })
        }, 
           async sendVoiceOtp(){ 
              HTTP.post(`login-voice-otp`,{
                 userId : this.$route.query.userId, 
                 regId : this.$route.query.regId, 
              }).then(({data}) => {
                //  if(!data.success){
                //     alert('failed');
                //   }else{ 
                //      alert('Success');
                //  }
              })
        }, 
           async resendOtp(){ 
              HTTP.post(`login-resend-otp`,{
                 userId : this.$route.query.userId, 
                 regId : this.$route.query.regId, 
              }).then(({data}) => {
                //  if(!data.success){
                //     alert('failed');
                //   }else{ 
                //      alert('Success');
                //  }
              })
        }, 
      loginWithPassword(){  
             this.$router.push({
                 name: 'LoginPassword',
                 query: {
                   userId : this.$route.query.userId, 
                   regId : this.$route.query.regId, 
                   phone : this.$route.query.phone, 
                   isPhone : this.$route.query.isPhone, 
               }
            });
      },  
    }, 
}
</script>
