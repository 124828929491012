<template> 
    <div class="a-box a-spacing-extra-large">
      <div class="a-box-inner a-padding-extra-large">
        <h1>
          Create new password
        </h1>
        <p>
          We'll ask for this password whenever you sign in.
        </p>
        <div class="auth-require-fields-match-group">
          <div class="a-row a-spacing-base">
            <label for="ap_password" class="a-form-label">
            New Password
            </label>
            <input autocomplete="off" ref="focus" type="password" maxlength="1024" id="ap_password" placeholder="At least 6 characters" v-model="user.password"  class="a-input-text a-span12 auth-required-field auth-require-fields-match auth-require-password-validation">
            <div class="a-box a-alert-inline a-alert-inline-info auth-inlined-information-message a-spacing-top-mini" aria-live="polite" aria-atomic="true">
              <div class="a-box-inner a-alert-container">
                <i class="a-icon a-icon-alert"></i>
                <div class="a-alert-content">
                  Passwords must be at least 6 characters.
                </div>
              </div>
            </div>
            <div id="auth-customerName-missing-alert" v-if="errors.password" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
              <div class="a-box-inner a-alert-container">
                <i class="a-icon a-icon-alert"></i>
                <div class="a-alert-content">
                  {{errors.password}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="auth-require-fields-match-group">
          <div class="a-row a-spacing-base">
            <label for="ap_password" class="a-form-label">
            Password Again
            </label>
            <input autocomplete="off" type="password" maxlength="1024" id="ap_password"  v-on:keyup.enter="register()" placeholder="At least 6 characters" v-model="user.password_confirmation" class="a-input-text a-span12 auth-required-field auth-require-fields-match auth-require-password-validation"> 
            <div id="auth-customerName-missing-alert" v-if="errors.password_confirmation" class="a-box a-alert-inline a-alert-inline-error auth-inlined-error-message a-spacing-top-mini" role="alert">
              <div class="a-box-inner a-alert-container">
                <i class="a-icon a-icon-alert"></i>
                <div class="a-alert-content">
                  {{errors.password_confirmation}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="a-section a-spacing-extra-large ap_mobile_number_fields">
          <span id="auth-continue" class="a-button a-button-span12 a-button-primary auth-requires-verify-modal"><span class="a-button-inner"><input autocomplete="off"  @click.prevent="register()" id="continue" class="a-button-input" type="submit" aria-labelledby="auth-continue-announce"><span id="auth-continue-announce" class="a-button-text" aria-hidden="true">
          Save changes and sign in
          </span></span></span>
        </div>
      </div>
    </div> 
</template>
<script> 
import {mapGetters,mapActions} from 'vuex'
import {HTTP} from '../../../http-common'
export default {
   name:'PasswordCreate',
   data(){
     return{
            user:{ 
                 password: '',
                 password_confirmation :'',
            }, 
            errors:''
      }
   },
    mounted(){
      this.$refs.focus.focus();
    },
   computed:{
          ...mapGetters({ countryCodes:['address/getCountryCodes'], }), },
   methods : {
      ...mapActions({
         fetchCountryCodes :'address/fetchCountryCodes', 
         login :'auth/login', 
      }),
      register(){
         this.errors = {},
         HTTP.post('submit-new-password',{
             password : this.user.password,
             password_confirmation : this.user.password_confirmation,
             userId : this.$route.query.userId,
             verification_token : this.$route.query.verification_token,
             isPhone : this.$route.query.isPhone,
         })
         .then(({data}) => { 
               if(!data.errors){ 
                  this.$router.push({
                     name: 'Login',
                     
                  });
               }else{
                  this.errors = data.errors;
               }
         })  
      }, 
    },
  created(){
        this.fetchCountryCodes();
      },
}
</script>
<style scoped>
   
</style>