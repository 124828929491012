<template>
  <div class="main-content-wrap section-ptb product-details-page">
         <div class="container">
      <router-view></router-view>    
         </div>
  </div> 
</template>
<script>
import { mapGetters,mapActions } from 'vuex'
export default {
    name : 'OrderIndex',
    computed : {
        ...mapGetters({
            // 'addresses' : 'address/all'
        })
    },
    
}
</script>