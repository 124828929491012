<template>
   
   <div class="main-content-wrap section-ptb product-details-page">
      <div class="container">
         <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
               <div class="box-part">
                  <div class="row box-inner">
                     <div class="col-2"> 
                        <i class="fa fa-shopping-bag fa-2x" aria-hidden="true"></i>  
                     </div>
                     <div class="col-10">
                        <router-link :to="{name:'Order'}">
                           <div class="box-heading">Your Orders</div>
                           <div class="box-desc">Track, return, or buy things again.</div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
               <div class="box-part">
                  <div class="row box-inner">
                     <div class="col-2"> 
                        <i class="fa fa-lock fa-3x" aria-hidden="true"></i>  
                     </div>
                     <div class="col-10">
                        <router-link :to="{name:'EditProfile'}">
                           <div class="box-heading">Login & Security</div>
                           <div class="box-desc">Edit login, name, and mobile number.</div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
               <div class="box-part">
                  <div class="row box-inner">
                     <div class="col-2"> 
                        <i class="fa fa-map-marker fa-3x" aria-hidden="true"></i>  
                     </div>
                     <div class="col-10">
                        <router-link :to="{name:'Address'}">
                           <div class="box-heading">Your Addresses</div>
                           <div class="box-desc">Edit addresses for orders and gifts.</div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
    name:'Account',
    mounted(){
      if(this.$root.refresh){
         location.reload();
      }
    }
}
</script>