<template>
    <div>
         <div class="blog-main-wrapper section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 order-2">
                        <aside class="blog-sidebar-wrapper"> 
                            <div class="blog-sidebar">
                                <h5 class="title">categories</h5>
                                <ul class="blog-archive blog-category">
                                    <li v-for="category in postCategories" :key="category.id">
                                 <router-link :to="{ name: 'blogCategoryIndex', params: { categoryId: category.slug }}">{{ category.name }}</router-link> 
                                    </li> 
                                </ul>
                            </div> 
                        </aside>
                    </div>
                    <div class="col-lg-9 order-1">
                        <div class="blog-item-wrapper"> 
                            <div class="blog-post-item blog-details-post">
                                <figure class="blog-thumb">
                                    <div class="blog-carousel-2 slick-row-15 slick-arrow-style slick-dot-style">
                                        <div class="blog-single-slide">
                                            <img :src="cover(post)" alt="blog image">
                                        </div> 
                                    </div>
                                </figure>
                                <div class="blog-content">
                                    <h3 class="blog-title">
                                       {{ post.title }}
                                    </h3>
                                    <div class="blog-meta"> 
                                    </div>
                                    <div class="entry-summary"> 
                                         <div v-html="post.body"></div> 
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>  
import {HTTP} from '../../../http-common'
export default {
    name : 'View',  
    data(){
         return{ 
             post : {},
             postCategories : {},
         }
    }, 
    beforeMount(){
        this.getPost();
        this.getPostCategories();
    }, 
    methods:{
         getPost : function(){
             HTTP.get(`post/${this.$route.params.url}`).then(res => {  
                this.post = res.data.post;
                if(this.post.meta_values){
                    this.post.meta_values.forEach(function(metaValue){ 

                            var meta = document.createElement('meta');
                            meta.name= metaValue.meta.name;
                            // meta.setAttribute('content',metaValue.content);
                            meta.content = metaValue.content; 
                          var result =   document.querySelector('head').appendChild(meta); 
                   });
                }
             });
         },
         getPostCategories : function(){
             HTTP.get(`post-categories`).then(res => {  
                this.postCategories = res.data.categories
             });
         },
        cover(post){
            if(post.cover_photo_url){
                return this.postCoverUrl(post.cover_photo_url);
            }else{
                return this.postCoverUrl('images/post-cover-demo.jpg');
            }
        },
    },
    // watch : {
    //     'post' : function(){
    //         if(this.post){
    //             this.post.metaValues.forEach(function(metaValue){ 
    //             });
    //         }
            
    //         // var meta=document.createElement('meta');
    //         // meta.name='viewport';

    //         // meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

    //         // document.getElementsByTagName('head')[0].appendChild(meta);
    //     }
    // }
} 
</script>

<style scoped>
.section-padding {
    padding-top: 0px;
    padding-bottom: 80px;
}
</style>