import {HTTP} from '../http-common'
import axios from 'axios';
import $ from 'jquery'
export default {
    namespaced : true,
    state :{
        categories:[],
        policyPages:[],
        knowUsPages:[],
        products : [],
        rattis : [],
        items : [],
        item : '',
        productAttributes : [],
        page:1,
        page2:1,
        cancelToken:'',
        domainStatus:false,
        domainName:'',
        domainDetails:'',
        StoreDetails:'',
        currency:'INR',
        exchangeRate:1,
        currencySign:'INR',
       
    },
    getters :{ 
        getCategories: (state)=>{
            return state.categories
        },
        getPolicyPages: (state)=>{
            return state.policyPages
        },
        getKnowUsPages: (state)=>{
            return state.knowUsPages
        },
        getProducts: (state)=>{
            return state.products
        },
        getRattis: (state)=>{
            return state.rattis
        },
        getProductAttributes: (state)=>{
            return state.productAttributes
        },
        getItems : (state) => {
            return state.items
        },
        getItem : (state) => {
            return state.item
        },
        getDomainStatus:(state) => {
            return state.domainStatus
        },
        getDomainName: (state) => {
            return state.domainName

        },
        getDomainDetails:(state) => {
            return state.domainDetails
        },
        getStoreDetails: (state) => {
            return state.StoreDetails
        },
        getCurrencySign: (state) => {
            return state.currencySign
        },
        getCurrency: (state) => {
            return state.currency
        }
    },
    mutations :{ 
        setCategories : (state,data) => {
            state.categories = data;
        },
        setPolicyPages : (state,data) => {
            state.policyPages = data;
        },
        setKnowUsPages : (state,data) => {
            state.knowUsPages = data;
        },
        setProducts : (state,data) => {
            state.products = data;
        },
        setRattis : (state,data) => {
            state.rattis = data;
        },
        setProductAttributes : (state,data) => {
            state.productAttributes = data;
        },
        setItems : (state,data) => {
            state.items = data;
        },
        setDomainStatus : (state,status) => {
            state.domainStatus=status
        },
        setDomainName : (state,name) =>  {
            state.domainName=name
        },
        setDomainDetails : (state,details) => {
            state.domainDetails=details
        },
        setStoreDetails :(state,details) => {
            state.StoreDetails=details
        },
    },
    actions :{ 
        fetchCategories(context){
            const productId = 2;
            HTTP.get(`categories/${productId}`).then(res => { 
                context.commit('setCategories',res.data);
            });
        },
       async fetchProducts({commit}){
            await HTTP.get(`products`).then(res => { 
                commit('setProducts',res.data); 
            });
        },
       async fetchPolicyPages({commit}){
            await HTTP.get(`policy-pages`).then(res => { 
                commit('setPolicyPages',res.data); 
            });
        },
       async fetchKnowUsPages({commit}){
            await HTTP.get(`know-us-pages`).then(res => { 
                commit('setKnowUsPages',res.data); 
            });
        },
        async fetchRattis(context){
           await HTTP.get(`rattis`).then(res => { 
                context.commit('setRattis',res.data);
            });
        },
        fetchProductAttributes({commit},productId){ 
            HTTP.get(`product-attributes/${productId}`).then(res => { 
                commit('setProductAttributes',res.data); 
            });
        },
        async fetchItems({commit},productId){  
            HTTP.get(`product-items/${productId}`).then(res => { 
                commit('setItems',res.data); 
                // $.each(res.data.data, (key, value) => {
                //     context.state.items.push(value);
                // }); 
                // context.state.page = context.state.page + 1;
                // context.state.items = res.data;
            });
            
        },
        fetchItem(context,itemId){
            HTTP.get(`item-details/${itemId}`).then(res => {  
                context.state.item = res.data;
            });
        },

       async fetchItemImage(context,itemId){
           return await axios.post('http://localhost/erp/api/fetch-item-image',{
                  'itemId':itemId
            }).then(res=>{
              return res.data;
            });
        },

        async fetchItemsByFilter(context,data){ 
            context.state.page2 = context.state.page2 + 1;
           await HTTP.post(`items-by-filter`,{
                data : data,
                page : context.state.page2,
            }).then(res => { 
                if(context.state.page2 == 1){
                    context.state.items = [];
                }
                $.each(res.data.data, (key, value) => {
                    context.state.items.push(value);
                }); 
                
                context.state.items = res.data;
            });  
        },
        async checkDomain(context,data){
           return await HTTP.get(`check-domain/${data}`).then(obj => {
               return JSON.parse(JSON.stringify(obj));
                  
            });
        },
        async changeCurrency(context,data){
            context.state.currency = data;
            localStorage.setItem('currency',data);
            if(data.currency == 'USD'){
                context.state.currency = 'USD';
                context.state.exchangeRate = 0.015;
                localStorage.setItem('currencySign','$');
            }else{
                context.state.currency = 'INR';
                context.state.exchangeRate = 1;
                context.state.currencySign = 'INR';
                localStorage.setItem('currencySign','Rs.');
            }
        },
        async convertPrice(context,data){
            
            if(localStorage.getItem('currency') == 'USD'){
                var result =  parseFloat(data)  * 0.015;
            }else{
                return parseFloat(data);
            } 
            return result.toFixed(2);
        },
    },
    modules :{

    }
}