<template>
  <!-- <div class="main-content-wrap section-ptb cart-page"> -->
    <div class="container  align-items-center mt-3">
      <div class="row justify-content-center  ">
        <div class="col-md-4">
          <div class="a-section a-spacing-none auth-navbar" data-v-776b4554="">
            <div class="a-section a-spacing-medium a-text-center" data-v-776b4554="">
                 <router-link class="nav-link p-0"   :to="{ name:'Homepage'}">
                     <img :src="domainProfile?.primary_logo?.path" alt="" style="max-width:200px;">
                  </router-link> 
                </div>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>
<script>   
import {mapGetters,mapActions} from 'vuex'
export default({
  name: 'DefaultLayout', 
    computed :{
        ...mapGetters({ 
            domainProfile : 'domain/profile', 
        })
    },
    created(){ 
        this.fetchProfile(); 
    }, 
    methods:{
        ...mapActions({
            'authUser' : 'auth/authUser', 
            'fetchProfile' : 'domain/fetchProfile'
        }), 
    }
});

</script>
<style>
   @font-face { 
  font-family:'Amazon Ember';
  src:local("Amazon Ember"),local("AmazonEmber-Regular"),url(https://m.media-amazon.com/images/S/sash/pDxWAF1pBB0dzGB.woff2) format("woff2"),url(https://m.media-amazon.com/images/S/sash/kfKKBuoqcD$AUKL.woff) format("woff");
} 
@font-face { 
  font-family:'Amazon Ember';
  font-style:italic;
  src:local("Amazon Ember"),local("AmazonEmber-Italic"),url(https://m.media-amazon.com/images/S/sash/WOnTLzkiaEccV7F.woff2) format("woff2"),url(https://m.media-amazon.com/images/S/sash/JJsp0ZvgpfwzJM6.woff) format("woff");
} 
@font-face { 
  font-family:'Amazon Ember';
  font-weight:700;
  src:local("Amazon Ember"),local("AmazonEmber-Bold"),url(https://m.media-amazon.com/images/S/sash/KFPk-9IF4FqAqY-.woff2) format("woff2"),url(https://m.media-amazon.com/images/S/sash/mzVbGSgvdBfRLX9.woff) format("woff");
} 
@font-face { 
  font-family:'Amazon Ember';
  font-style:italic;
  font-weight:700;
  src:local("Amazon Ember"),local("AmazonEmber-BoldItalic"),url(https://m.media-amazon.com/images/S/sash/2SazJx$EeTHfhMN.woff2) format("woff2"),url(https://m.media-amazon.com/images/S/sash/CjbL$jCCegyfqZ7.woff) format("woff");
} 
/* * { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
}  */

/* body { 
    min-height:   100%;
    padding:  0; 
    margin:  0; 
    background:  #fff;
    font-size: 13px; 
    line-height: 19px; 
    color: #111; 
    font-family: Arial,sans-serif;
} 

.a-ember body { 
    font-family: "Amazon Ember",Arial,sans-serif;
} 

html { 
    font-size: 100%; 
    -webkit-text-size-adjust: 100%;
}  */

.a-padding-medium { 
    padding: 14px 18px!important;
} 

.a-section { 
    margin-bottom: 22px;
} 

.a-spacing-none { 
    margin-bottom: 0!important;
} 

#authportal-center-section { 
    width: 700px; 
    margin: auto;
} 

.a-spacing-top-extra-large { 
    margin-top: 26px!important;
} 

.a-section:last-child { 
    margin-bottom: 0;
} 

.a-spacing-medium { 
    margin-bottom: 18px!important;
} 

.a-text-center { 
    text-align: center!important;
} 

#authportal-main-section { 
    margin-top: 10px;
} 

.a-spacing-small { 
    margin-bottom: 10px!important;
} 

.a-size-mini { 
    font-size: 11px!important; 
    line-height: 1.465!important;
} 

/* a,a:link { 
    text-decoration: none; 
    color: #0066c0;
}  */

a:active,a:hover { 
    outline: 0;
} 

a:hover { 
    text-decoration: underline; 
    cursor: pointer; 
    color: #c45500;
} 

.a-spacing-base { 
    margin-bottom: 14px!important;
} 

.auth-workflow .auth-pagelet-container  { 
    width: 350px; 
    margin: 0 auto;
} 

.a-divider.a-divider-section .a-divider-inner  { 
    height: 44px; 
    margin-bottom: -18px; 
    background: -webkit-linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent); 
    background: linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent); 
    z-index: 0; 
    zoom: 1;
} 

.a-divider.a-divider-section .a-divider-inner:after ,.a-divider.a-divider-section .a-divider-inner:after ,.a-divider.a-divider-section .a-divider-inner:after  { 
    display: block; 
    width: 100%; 
    height: 44px; 
    background-color: transparent; 
    background: -webkit-linear-gradient(to right,#fff,rgba(255,255,255,0),#fff); 
    background: linear-gradient(to right,#fff,rgba(255,255,255,0),#fff); 
    z-index: 1; 
    content: "";
} 

.auth-footer-seperator { 
    display: inline-block; 
    width: 20px;
} 

.a-color-secondary { 
    color: #555!important;
} 

i { 
    font-style: italic;
} 

.a-icon { 
    background-image: url(https://m.media-amazon.com/images/S/sash/mPGmT0r6IeTyIee.png); 
    -webkit-background-size: 400px 750px; 
    background-size: 400px 750px; 
    background-repeat: no-repeat; 
    display: inline-block; 
    vertical-align: top; 
    background-repeat-x: no-repeat; 
    background-repeat-y: no-repeat;
} 

.a-icon-logo { 
    background-position: -5px -130px; 
    height: 31px; 
    width: 103px;
} 

.a-icon-domain { 
    height: 28px;
} 

.a-icon-domain-in { 
    width: 14px; 
    background-position: -228px -200px;
} 

.a-box { 
    display: block; 
    border-radius: 4px; 
    border: 1px #ddd solid; 
    background-color: #fff;
} 

.a-alert-warning { 
    border-color: #8b6e00;
} 

.a-box  { 
    border-radius: 8px;
} 

#auth-cookie-warning-message { 
    display: none;
} 

.a-box .a-box-inner  { 
    border-radius: 4px; 
    position: relative; 
    padding: 14px 18px;
} 

.a-alert-warning .a-alert-container  { 
    background-color: #fff; 
    box-shadow: 0 0 0 4px #fffae7 inset; 
    padding-left: 63px;
} 

.a-box .a-box-inner  { 
    border-radius: 8px;
} 

.a-divider.a-divider-break { 
    text-align: center; 
    position: relative; 
    top: 2px; 
    padding-top: 1px; 
    margin-bottom: 14px; 
    line-height: 0;
} 

.a-divider.a-divider-break:after ,.a-divider.a-divider-break:after ,.a-divider.a-divider-break:after { 
    content: ""; 
    width: 100%; 
    background-color: transparent; 
    display: block; 
    height: 1px; 
    border-top: 1px solid #e7e7e7; 
    position: absolute; 
    top: 50%; 
    margin-top: -1px; 
    z-index: 1;
} 

.a-button { 
    background: #e7e9ec; 
    border-radius: 3px; 
    border-color: #adb1b8 #a2a6ac #8d9096; 
    border-style: solid; 
    border-width: 1px; 
    cursor: pointer; 
    display: inline-block; 
    padding: 0; 
    text-align: center; 
    text-decoration: none!important; 
    vertical-align: middle;
} 

.a-button-span12 { 
    width: 100%!important;
} 

.a-button  { 
    background: #FFF; 
    border-color: #D5D9D9;
} 

.a-button:not(.a-button-toggle)  { 
    border-radius: 8px; 
    box-shadow: 0 2px 5px 0 rgba(213,217,217,.5);
} 

.a-button:hover { 
    border-color: #a2a6ac #979aa1 #82858a;
} 

.a-button:active,.a-button:active:hover { 
    border-color: #adb1b8 #a2a6ac #a2a6ac;
} 

.a-button:hover  { 
    background-color: #F7FAFA; 
    border-color: #D5D9D9;
} 

h4 { 
    padding:   0; 
    margin:   0;
    font-family:  Arial,sans-serif; 
    text-rendering:  optimizeLegibility; 
    padding-bottom:  4px;
    font-weight: 400; 
    font-size: 17px; 
    line-height: 1.255;
} 

h4  { 
    font-family: "Amazon Ember",Arial,sans-serif;
} 

.a-alert-warning .a-alert-container .a-alert-heading  { 
    color: #7c6200;
} 

.a-alert-warning .a-icon-alert  { 
    background-position: -283px -35px;
} 

.a-alert .a-icon-alert  { 
    height: 27px; 
    width: 30px; 
    position: absolute; 
    left: 18px; 
    top: 11px;
} 

form { 
    margin-bottom: 14px;
} 

h5 { 
    padding:  0; 
    margin:  0;
    font-weight: 700; 
    font-size: 13px; 
    line-height: 19px;
} 

.a-divider.a-divider-break h5  { 
    line-height: 1; 
    font-size: 12px; 
    color: #767676; 
    font-weight: 400; 
    z-index: 2; 
    position: relative; 
    display: inline-block; 
    background-color: #fff; 
    padding: 0 8px 0 7px;
} 

.a-button-inner { 
    display: block; 
    position: relative; 
    overflow: hidden; 
    height: 29px; 
    box-shadow: 0 1px 0 rgba(255,255,255,.6) inset; 
    border-radius: 2px;
} 

.a-button .a-button-inner  { 
    background: #eff0f3; 
    background: -webkit-linear-gradient(top,#f7f8fa,#e7e9ec); 
    background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
} 

.a-button:not(.a-button-toggle) .a-button-inner  { 
    border-radius: 7px; 
    background: 0 0; 
    box-shadow: none;
} 

.a-button:hover .a-button-inner  { 
    background: #e0e3e8; 
    background: -webkit-linear-gradient(top,#e7eaf0,#d9dce1); 
    background: linear-gradient(to bottom,#e7eaf0,#d9dce1);
} 

p { 
    padding: 0; 
    margin: 0 0 14px 0;
} 

p:last-child { 
    margin-bottom: 0;
} 

input { 
    margin:   0; 
    font-size:   100%; 
    vertical-align:   middle;
    line-height:  19px; 
    -webkit-transition: all .1s linear; 
    transition: all .1s linear; 
    font-family: Arial,sans-serif; 
    color: #111;
} 

input  { 
    font-family:  "Amazon Ember",Arial,sans-serif;
    color: #0F1111;
} 

.a-button-text { 
    background-color: transparent; 
    border: 0; 
    display: block; 
    font-family: Arial,sans-serif; 
    font-size: 13px; 
    line-height: 29px; 
    margin: 0; 
    outline: 0; 
    padding: 0 10px 0 11px; 
    text-align: center; 
    white-space: nowrap;
} 

a.a-button-text { 
    width: 100%; 
    height: 100%;
} 

.a-button a  { 
    color: #111; 
    text-decoration: none!important;
} 

.a-button .a-button-text  { 
    color: #111;
} 

.a-button-text  { 
    font-family: "Amazon Ember",Arial,sans-serif;
} 

.a-button .a-button-text  { 
    color: #0F1111;
} 

.a-button a ,.a-button:hover a  { 
    color: #111; 
    text-decoration: none!important;
} 

.a-button .a-button-text:hover  { 
    color: #0F1111;
} 

p a ,p a:link  { 
    text-decoration: underline;
} 

.a-padding-extra-large { 
    padding: 20px 26px!important;
} 

h1 { 
    padding:   0; 
    margin:   0;
    font-family:  Arial,sans-serif; 
    text-rendering:  optimizeLegibility; 
    padding-bottom:  4px;
    font-weight: 400; 
    font-size: 28px; 
    line-height: 1.2;
} 

h1  { 
    font-family: "Amazon Ember",Arial,sans-serif;
} 

.a-row { 
    width: 100%;
} 

.a-row:after,.a-row:before { 
    display: table; 
    content: ""; 
    line-height: 0; 
    font-size: 0;
} 

.a-row:after { 
    clear: both;
} 

label { 
    display: block; 
    padding-left: 2px; 
    padding-bottom: 2px !important; 
    margin-bottom : 0px !important;
    font-weight: 700;
} 

.a-input-text { 
    border: 1px solid #a6a6a6; 
    border-top-color: #949494; 
    border-radius: 3px; 
    box-shadow: 0 1px 0 rgba(255,255,255,.5),0 1px 0 rgba(0,0,0,.07) inset; 
    outline: 0;
} 

/* .a-input-text,input[type="email"] { 
    background-color: #fff; 
    height: 31px; 
    padding: 3px 7px; 
    line-height: normal;
}  */

.a-row .a-span12  { 
    width: 99.948%;
} 

.a-row .a-span12 ,.a-span12 { 
    width: 100%; 
    margin-right: 0;
} 

.a-input-text:focus { 
    border-color: #e77600; 
    box-shadow: 0 0 3px 2px rgba(228,121,17,.5);
} 

.a-input-text:focus  { 
    background-color: #F7FEFF; 
    border-color: #007185; 
    box-shadow: 0 0 0 3px #c8f3fa,0 1px 2px rgba(15,17,17,.15) inset;
} 

.hide { 
    display: none;
} 

.a-input-text,input[type="password"] { 
    border:  1px solid #a6a6a6; 
    border-top-color:  #949494; 
    border-radius:  3px; 
    box-shadow:  0 1px 0 rgba(255,255,255,.5),0 1px 0 rgba(0,0,0,.07) inset; 
    outline:  0;
    background-color: #fff; 
    height: 31px; 
    padding: 3px 7px; 
    line-height: normal;
} 

input:not([type="hidden"]) + input  { 
    margin-left: .385em;
} 

.a-spacing-top-mini { 
    margin-top: 6px!important;
} 

.a-alert-inline { 
    display: inline-block; 
    border: none; 
    vertical-align: middle; 
    background-color: transparent;
} 

/* .auth-inlined-error-message { 
    display: none;
}  */

.a-button-primary { 
    background: #f0c14b; 
    border-color: #a88734 #9c7e31 #846a29; 
    color: #111;
} 

.a-button-primary  { 
    background: #FFD814; 
    border-color: #FCD200;
} 

.a-button-primary:hover,.a-button-primary:hover:active { 
    border-color: #a88734 #9c7e31 #846a29;
} 

.a-button-primary:hover  { 
    background: #F7CA00; 
    border-color: #F2C200;
} 

.a-spacing-top-medium { 
    margin-top: 18px!important;
} 

.a-size-small { 
    font-size: 12px!important; 
    line-height: 1.5!important;
} 

.a-alert-inline .a-alert-container  { 
    padding: 0;
} 

.a-alert-inline-error .a-alert-container  { 
    padding-left: 16px; 
    color: #c40000;
} 

.a-button-primary .a-button-inner  { 
    background:   #f3d078; 
    background:   -webkit-linear-gradient(top,#f7dfa5,#f0c14b); 
    background:   linear-gradient(to bottom,#f7dfa5,#f0c14b);
    box-shadow:  0 2px 5px 0 rgba(213,217,217,.5);
} 

.a-button-primary:hover .a-button-inner  { 
    background: #f1c860; 
    background: -webkit-linear-gradient(top,#f5d78e,#eeb933); 
    background: linear-gradient(to bottom,#f5d78e,#eeb933);
} 

.a-expander-header { 
    outline: 0;
} 

a.a-link-expander { 
    display: inline-block; 
    position: relative; 
    padding-left: 11px;
} 

.a-expander-inner { 
    margin-top: 4px; 
    padding-left: 11px;
} 

.a-alert-inline-error .a-icon-alert  { 
    width: 5px; 
    background-position: -141px -130px;
} 

.a-alert-inline .a-icon-alert  { 
    height: 13px; 
    width: 14px; 
    position: absolute; 
    left: 2px; 
    top: 2px;
} 

.a-alert-inline .a-alert-container .a-alert-content  { 
    margin-bottom: 0; 
    text-align: left; 
    font-size: 12px; 
    line-height: 15px;
} 

.a-button-input { 
    position: absolute; 
    background-color: transparent; 
    color: transparent; 
    border: 0; 
    cursor: pointer; 
    height: 100%; 
    width: 100%; 
    left: 0; 
    top: 0; 
    opacity: .01; 
    outline: 0; 
    overflow: visible; 
    z-index: 20;
} 

input[type="submit"] { 
    cursor: pointer; 
    -webkit-appearance: button;
} 

.a-button-primary .a-button-text  { 
    color:  #0F1111;
} 

.a-icon-expand { 
    width: 7px; 
    height: 7px; 
    background-position: -366px -86px; 
    position: absolute; 
    top: 50%; 
    margin-top: -4px; 
    left: 0;
} 

.a-expander-prompt { 
    word-wrap: break-word;
} 

.cvf-widget-container { 
    width: 27em!important; 
    margin-left: auto; 
    margin-right: auto;
} 
/* Saab */

.a-box { 
    display: block; 
    border-radius: 4px; 
    border: 1px #ddd solid; 
    background-color: #fff;
} 

.a-spacing-extra-large { 
    margin-bottom: 26px!important;
} 

.a-box  { 
    border-radius: 8px;
} 

form { 
    margin-bottom: 14px;
} 

.a-section { 
    margin-bottom: 22px;
} 

.auth-workflow .auth-pagelet-container { 
    width: 350px; 
    margin: 0 auto;
} 

.a-section:last-child { 
    margin-bottom: 0;
} 

#authportal-main-section { 
    margin-top: 10px;
} 

#authportal-center-section { 
    width: 700px; 
    margin: auto;
} 

.a-padding-medium { 
    padding: 14px 18px!important;
} 

body { 
    min-height:   100%;
    padding:  0; 
    margin:  0; 
    background:  #fff;
    font-size: 13px; 
    line-height: 19px; 
    color: #111; 
    font-family: Arial,sans-serif;
} 

.a-ember body { 
    font-family: "Amazon Ember",Arial,sans-serif;
} 

html { 
    font-size: 100%; 
    -webkit-text-size-adjust: 100%;
} 

.a-box .a-box-inner  { 
    border-radius:  8px;
    position:  relative; 
    padding:  14px 18px;
} 

h1 { 
    padding:   0; 
    margin:   0;
    font-family:  Arial,sans-serif; 
    text-rendering:  optimizeLegibility; 
    padding-bottom:  4px;
    font-weight: 400; 
    font-size: 28px; 
    line-height: 1.2;
} 

.a-spacing-small { 
    margin-bottom: 10px!important;
} 

h1  { 
    font-family: "Amazon Ember",Arial,sans-serif;
} 

.a-row { 
    width: 100%;
} 

.a-spacing-base { 
    margin-bottom: 14px!important;
} 

.a-row:after,.a-row:before { 
    display: table; 
    content: ""; 
    line-height: 0; 
    font-size: 0;
} 

.a-row:after { 
    clear: both;
} 

.a-spacing-top-mini { 
    margin-top: 6px!important;
} 

.aok-hidden { 
    display: none!important; 
    visibility: hidden!important;
} 

.a-spacing-top-mini.a-section { 
    padding-top: 6px; 
    margin-top: 0!important;
} 

.a-spacing-top-medium { 
    margin-top: 18px!important;
} 

.a-size-small { 
    font-size: 12px!important; 
    line-height: 1.5!important;
} 

label { 
    display: block; 
    padding-left: 2px; 
    padding-bottom: 2px; 
    font-weight: 700;
} 

input { 
    margin:   0; 
    font-size:   100%; 
    vertical-align:   middle;
    line-height:  19px; 
    -webkit-transition: all .1s linear; 
    transition: all .1s linear; 
    font-family: Arial,sans-serif; 
    color: #111;
} 

/* .a-input-text,input[type="text"] { 
    border: 1px solid #a6a6a6; 
    border-top-color: #949494; 
    border-radius: 3px; 
    box-shadow: 0 1px 0 rgba(255,255,255,.5),0 1px 0 rgba(0,0,0,.07) inset; 
    outline: 0;
}  */

input  { 
    font-family: "Amazon Ember",Arial,sans-serif;
} 

/* .a-input-text,input[type="text"] { 
    background-color: #fff; 
    height: 31px; 
    padding: 3px 7px; 
    line-height: normal;
}  */

input  { 
    color: #0F1111;
} 

.a-row .a-span12  { 
    width: 99.948%;
} 

.a-row .a-span12 ,.a-span12 { 
    width: 100%; 
    margin-right: 0;
} 

[autocomplete]:not(:-webkit-autofill)  { 
    animation-name: onAutoFillChanged;
} 

.a-alert-inline { 
    display: inline-block; 
    border: none; 
    vertical-align: middle; 
    background-color: transparent;
} 

/* .auth-inlined-error-message { 
    display: none;
}  */

.a-spacing-micro { 
    margin-bottom: 4px!important;
} 

.a-button { 
    background: #e7e9ec; 
    border-radius: 3px; 
    border-color: #adb1b8 #a2a6ac #8d9096; 
    border-style: solid; 
    border-width: 1px; 
    cursor: pointer; 
    display: inline-block; 
    padding: 0; 
    text-align: center; 
    text-decoration: none!important; 
    vertical-align: middle;
} 

.a-button-primary { 
    background: #f0c14b; 
    border-color: #a88734 #9c7e31 #846a29; 
    color: #111;
} 

.a-button-span12 { 
    width: 100%!important;
} 

.a-button  { 
    background: #FFF; 
    border-color: #D5D9D9;
} 

.a-button-primary  { 
    background: #FFD814; 
    border-color: #FCD200;
} 

.a-button:not(.a-button-toggle)  { 
    border-radius: 8px; 
    box-shadow: 0 2px 5px 0 rgba(213,217,217,.5);
} 

.a-button:hover { 
    border-color: #a2a6ac #979aa1 #82858a;
} 

.a-button:active,.a-button:active:hover { 
    border-color: #adb1b8 #a2a6ac #a2a6ac;
} 

.a-button-primary:hover,.a-button-primary:hover:active { 
    border-color: #a88734 #9c7e31 #846a29;
} 

.a-button:hover  { 
    background-color: #F7FAFA; 
    border-color: #D5D9D9;
} 

.a-button-primary:hover  { 
    background: #F7CA00; 
    border-color: #F2C200;
} 

.a-divider.a-divider-section .a-divider-inner ,.a-divider.a-divider-section .a-divider-inner  { 
    height: 44px; 
    margin-bottom: -18px; 
    background: -webkit-linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent); 
    background: linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent); 
    z-index: 0; 
    zoom: 1;
} 

.a-divider.a-divider-section .a-divider-inner:after ,.a-divider.a-divider-section .a-divider-inner:after ,.a-divider.a-divider-section .a-divider-inner:after  { 
    display: block; 
    width: 100%; 
    height: 44px; 
    background-color: transparent; 
    background: -webkit-linear-gradient(to right,#fff,rgba(255,255,255,0),#fff); 
    background: linear-gradient(to right,#fff,rgba(255,255,255,0),#fff); 
    z-index: 1; 
    content: "";
} 

/* a,a:link { 
    text-decoration: none; 
    color: #0066c0;
}  */

a.a-link-emphasis { 
    position: relative; 
    padding-right: 9px;
} 

.a-icon,.a-link-emphasis:after { 
    background-image: url(https://m.media-amazon.com/images/S/sash/mPGmT0r6IeTyIee.png); 
    -webkit-background-size: 400px 750px; 
    background-size: 400px 750px; 
    background-repeat: no-repeat; 
    display: inline-block; 
    vertical-align: top; 
    background-repeat-x: no-repeat; 
    background-repeat-y: no-repeat;
} 

a.a-icon-link-emphasis:after,a.a-link-emphasis:after { 
    background-image: url(https://m.media-amazon.com/images/S/sash/mPGmT0r6IeTyIee.png); 
    -webkit-background-size: 400px 750px; 
    background-size: 400px 750px; 
    background-repeat: no-repeat; 
    pointer-events: none; 
    content: ""; 
    display: block; 
    position: absolute; 
    width: 3px; 
    right: 1px; 
    top: 50%; 
    height: 6px; 
    margin-top: -3px; 
    background-position: -346px -86px; 
    vertical-align: top; 
    background-repeat-x: no-repeat; 
    background-repeat-y: no-repeat;
} 

a:active,a:hover { 
    outline: 0;
} 

a:hover { 
    text-decoration: underline; 
    cursor: pointer; 
    color: #c45500;
} 

a.a-icon-link-emphasis:hover:after,a.a-link-emphasis:hover:after { 
    background-position: -346px -101px;
} 

.a-alert-inline .a-alert-container  { 
    padding: 0;
} 

.a-alert-inline-error .a-alert-container  { 
    padding-left: 16px; 
    color: #c40000;
} 

.a-fixed-left-grid { 
    position: relative;
} 

.a-input-text { 
    border: 1px solid #a6a6a6; 
    border-top-color: #949494; 
    border-radius: 3px; 
    box-shadow: 0 1px 0 rgba(255,255,255,.5),0 1px 0 rgba(0,0,0,.07) inset; 
    outline: 0;
} 

/* .a-input-text,input[type="email"] { 
    background-color: #fff; 
    height: 31px; 
    padding: 3px 7px; 
    line-height: normal;
}  */

.a-input-text,input[type="password"] { 
    border:  1px solid #a6a6a6; 
    border-top-color:  #949494; 
    border-radius:  3px; 
    box-shadow:  0 1px 0 rgba(255,255,255,.5),0 1px 0 rgba(0,0,0,.07) inset; 
    outline:  0;
    background-color: #fff; 
    height: 31px; 
    padding: 3px 7px; 
    line-height: normal;
} 

.auth-inlined-information-message { 
    display: inline-block;
} 

.a-button-inner { 
    display: block; 
    position: relative; 
    overflow: hidden; 
    height: 29px; 
    box-shadow: 0 1px 0 rgba(255,255,255,.6) inset; 
    border-radius: 2px;
} 

.a-button .a-button-inner  { 
    background: #eff0f3; 
    background: -webkit-linear-gradient(top,#f7f8fa,#e7e9ec); 
    background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
} 

.a-button-primary .a-button-inner  { 
    background:   #f3d078; 
    background:   -webkit-linear-gradient(top,#f7dfa5,#f0c14b); 
    background:   linear-gradient(to bottom,#f7dfa5,#f0c14b);
    box-shadow:  0 2px 5px 0 rgba(213,217,217,.5);
} 

.a-button:not(.a-button-toggle) .a-button-inner  { 
    border-radius: 7px; 
    background: 0 0; 
    box-shadow: none;
} 

.a-button:hover .a-button-inner  { 
    background: #e0e3e8; 
    background: -webkit-linear-gradient(top,#e7eaf0,#d9dce1); 
    background: linear-gradient(to bottom,#e7eaf0,#d9dce1);
} 

.a-button-primary:hover .a-button-inner  { 
    background: #f1c860; 
    background: -webkit-linear-gradient(top,#f5d78e,#eeb933); 
    background: linear-gradient(to bottom,#f5d78e,#eeb933);
} 

i { 
    font-style: italic;
} 

.a-icon { 
    background-image: url(https://m.media-amazon.com/images/S/sash/mPGmT0r6IeTyIee.png); 
    -webkit-background-size: 400px 750px; 
    background-size: 400px 750px; 
    background-repeat: no-repeat; 
    display: inline-block; 
    vertical-align: top; 
    background-repeat-x: no-repeat; 
    background-repeat-y: no-repeat;
} 

.a-alert-inline-error .a-icon-alert  { 
    width: 5px; 
    background-position: -141px -130px;
} 

.a-alert-inline .a-icon-alert  { 
    height: 13px; 
    width: 14px; 
    position: absolute; 
    left: 2px; 
    top: 2px;
} 

.a-alert-inline .a-alert-container .a-alert-content  { 
    margin-bottom: 0; 
    text-align: left; 
    font-size: 12px; 
    line-height: 15px;
} 

.a-fixed-left-grid .a-fixed-left-grid-inner  { 
    position: relative; 
    padding: 0;
} 

.a-fixed-left-grid .a-fixed-left-grid-inner:after ,.a-fixed-left-grid .a-fixed-left-grid-inner:before  { 
    display: table; 
    content: ""; 
    line-height: 0; 
    font-size: 0;
} 

.a-fixed-left-grid .a-fixed-left-grid-inner:after  { 
    clear: both;
} 

.a-alert-inline-info .a-alert-container  { 
    padding-left: 16px; 
    color: #2b2b2b;
} 

.a-button-input { 
    position: absolute; 
    background-color: transparent; 
    color: transparent; 
    border: 0; 
    cursor: pointer; 
    height: 100%; 
    width: 100%; 
    left: 0; 
    top: 0; 
    opacity: .01; 
    outline: 0; 
    overflow: visible; 
    z-index: 20;
} 

input[type="submit"] { 
    cursor: pointer; 
    -webkit-appearance: button;
} 

.a-button-text { 
    background-color: transparent; 
    border: 0; 
    display: block; 
    font-family: Arial,sans-serif; 
    font-size: 13px; 
    line-height: 29px; 
    margin: 0; 
    outline: 0; 
    padding: 0 10px 0 11px; 
    text-align: center; 
    white-space: nowrap;
} 

.a-button .a-button-text  { 
    color: #111;
} 

.a-button-text  { 
    font-family: "Amazon Ember",Arial,sans-serif;
} 

.a-button-primary .a-button-text  { 
    color: #111;
} 

.a-button .a-button-text  { 
    color: #0F1111;
} 

.a-button-primary .a-button-text  { 
    color: #0F1111;
} 

.a-button .a-button-text:hover  { 
    color: #0F1111;
} 

.a-fixed-left-grid .a-fixed-left-grid-col  { 
    position: relative; 
    overflow: visible; 
    zoom: 1; 
    min-height: 1px;
} 

.a-fixed-left-grid .a-col-right  { 
    width: 100%; 
    padding-left: 3.5%;
} 

.a-alert-inline-info .a-icon-alert  { 
    width: 6px; 
    background-position: -166px -130px;
} 

.a-dropdown-container { 
    position: relative;
} 

.a-input-text,input[type="tel"] { 
    border:  1px solid #a6a6a6; 
    border-top-color:  #949494; 
    border-radius:  3px; 
    box-shadow:  0 1px 0 rgba(255,255,255,.5),0 1px 0 rgba(0,0,0,.07) inset; 
    outline:  0;
    background-color: #fff; 
    height: 31px; 
    padding: 3px 7px; 
    line-height: normal;
} 

select { 
    margin:  0; 
    font-size:  100%; 
    vertical-align:  middle;
    -webkit-transition: all .1s linear; 
    transition: all .1s linear; 
    font-family: Arial,sans-serif; 
    line-height: 19px; 
    color: #111;
} 

select  { 
    font-family:  "Amazon Ember",Arial,sans-serif;
    color: #0F1111;
} 

.a-native-dropdown  { 
    position: absolute; 
    z-index: -1; 
    opacity: .01; 
    max-width: 100%;
} 

.a-button-dropdown .a-button-text  { 
    padding-right: 22px; 
    text-align: left; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    position: relative; 
    z-index: 10;
} 

.a-icon-dropdown { 
    width: 5px; 
    height: 8px; 
    background-position: -65px -5px;
} 

.a-button .a-icon-dropdown  { 
    position: absolute; 
    top: 10px; 
    right: 9px;
} 


@keyframes onAutoFillChanged { 
  0% {  
      dummy: none; 
  }  
  100% {  
      dummy: none; 
  }  

} 


</style>